import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function GetVehicleModel(props) {
  const navigate = useNavigate();

  useEffect(() => {
    let logIn = JSON.parse(localStorage.getItem("login"));
    if (logIn != undefined) {
      if (!logIn) {
        navigate("/log-in");
      }
    } else {
      navigate("/log-in");
    }
  }, []);

  return (
    <div className="">
      <img
        src={require("../../assets/bg.png")}
        className="h-[100vh] w-[100vw]"
      />
      <div className="absolute flex top-0 bottom-0 left-0 right-0 justify-center items-center flex-col">
        <div className="w-[80%] h-[60%] overflow-auto justify-center items-center text-center">
          <Formik
            initialValues={{
              vehicle_model: "",
            }}
            validate={(values) => {
              const errors = {};
              var VehicleModelError = /^[a-zA-Z0-9 ]*$/;
              if (!values.vehicle_model.trim("")) {
                errors.vehicle_model = "Vehicle Model is Required";
              } else if (!VehicleModelError.test(values.vehicle_model)) {
                errors.vehicle_model = "Please Enter Valid Vehicle Model";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                navigate("/paint-formulation-report", {
                  state: {
                    type: "VehicleModel",
                    vehicle_model: values.vehicle_model,
                  },
                });
                setSubmitting(false);
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => {
              return (
                <form onSubmit={handleSubmit} autocomplete="off">
                  <div className="items-center flex flex-col">
                    {/* <img src={Logo} className='w-5/6' /> */}
                    <div className="rounded-lg sm:w-[65%] w-[90%] items-center flex flex-col py-4">
                      <div className="w-[90%] text-left uppercase mb-1 mt-4 text-[#9d9fa2]">
                        Vehicle Model<span>*</span>
                      </div>
                      <TextField
                        name="vehicle_model"
                        id="vehicle_model"
                        value={values.vehicle_model}
                        className="w-[90%] bg-[#9d9fa2]"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                        onBlur={handleBlur}
                        placeholder="Enter Vehicle Model"
                        onChange={(event, value) => {
                          setFieldValue("vehicle_model", event.target.value);
                          localStorage.setItem(
                            "vehicleModel",
                            event.target.value
                          );
                        }}
                      />
                      <div className="text-red-500 w-[90%] justify-start text-left">
                        {errors.vehicle_model &&
                          touched.vehicle_model &&
                          errors.vehicle_model}
                      </div>
                    </div>
                    <div className="flex justify-center items-center sm:items-baseline w-[80%] sm:w-[60%] my-6 sm:flex sm:flex-row flex-col">
                      <Button
                        variant="contained"
                        type="submit"
                        // disabled={isSubmitting}
                        endIcon={
                          <div className="bg-[#1e3e88] flex items-center absolute top-0 bottom-0 right-0 px-1">
                            <ChevronRightIcon />
                          </div>
                        }
                        style={{
                          backgroundColor: "#3c5a9f",
                          paddingRight: 50,
                          paddingLeft: 15,
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default GetVehicleModel;
