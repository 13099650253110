import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React from "react";
import { useNavigate } from "react-router-dom";

const GoBackDialog = (props) => {
    const navigate = useNavigate();

    return (
        <>
            <Dialog
                open={props.isShowBackModel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: { borderRadius: 15 }
                }}
            >
                <DialogTitle id="alert-dialog-title" className="text-center font-bold uppercase rounded-lg">
                    <div className='px-6 py-4'>
                        <div className='text-xs font-bold'>
                            Are you sure you want to go back without save data?
                        </div>
                        <div className="flex justify-between w-full mt-8">
                            <div>
                                <Button
                                    onClick={() => {
                                        props.setIsActiveWindow(props.isActiveWindow - 1)
                                    }}
                                    variant="outlined"
                                    startIcon={<ChevronLeftIcon />}
                                    sx={{
                                        color: '#002783',
                                        border: '1px solid #002783',
                                    }}>
                                    Back
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={() => { props.setIsShowBackModel(false) }}
                                    variant='outlined'
                                    sx={{
                                        color: '#002783',
                                        border: '1px solid #002783',
                                    }}>
                                    Close
                                </Button>
                            </div>
                        </div>
                    </div>
                </DialogTitle>
            </Dialog>
        </>
    );
};

export default GoBackDialog;
