import React from "react"
import { AppContext } from "./app.contexts"


export default function WithAppContext(Component) {
  return function contextComponent(props) {
    return (
      <AppContext.Consumer>
        {(context) => <Component {...props} context={context} />}
      </AppContext.Consumer>
    )
  }
}
