// export const AppHeader =
//   window.origin.indexOf("dqnq6jt4nx13u.cloudfront.net") > -1
//     ? {
//       "Content-Type": "application/json",
//       "X-I2CE-ENTERPRISE-ID": "maruti_core",
//       "X-I2CE-USER-ID": "mpf_admin@mpf.com",
//       "X-I2CE-API-KEY": "bXBmX2FkbWluQG1wZi5jb20xNjYyNjQ1MzYxLjkz",
//     }
//     : {
//       "Content-Type": "application/json",
//       "X-I2CE-ENTERPRISE-ID": "maruti_core",
//       "X-I2CE-USER-ID": "ananth+maruti_core@i2ce.in",
//       "X-I2CE-API-KEY": "78e739e1-c90b-373d-8bc3-ea84080b4136",
//     };
// // export const AppHeader = {
// //     'Content-Type':'application/json',
// //     'X-I2CE-ENTERPRISE-ID':'maruti_core',
// //     'X-I2CE-USER-ID':'ananth+maruti_core@i2ce.in',
// //     'X-I2CE-API-KEY':'78e739e1-c90b-373d-8bc3-ea84080b4136'
// // }
// export const CarDetailApi = {
//   "Content-Type": "application/json",
//   token: "dR$545#h^jjmanJ",
// };

// export const ApiURL = 'https://staging.iamdave.ai/'

// export const ApiURL =
//   window.origin.indexOf("dqnq6jt4nx13u.cloudfront.net") > -1
//     ? "https://msil-core.iamdave.ai/"
//     : "https://staging.iamdave.ai/";

// var ApiURL; 

// if (window.origin.indexOf('dqnq6jt4nx13u.cloudfront.net') > -1) {
//   ApiURL = "https://staging.iamdave.ai/";
// } else {
//   ApiURL = "https://msil-core.iamdave.ai/";
// }

// export ApiURL;
// if (window.origin.indexOf("https://www.msilpaintformulation.com")) {
//    const ApiURL = "https://msil-core.iamdave.ai/"
// }else if (window.origin.indexOf.includes("http://localhost:3000")) {
//    const ApiURL = "https://staging.iamdave.ai/"
// }

export const ApiURL='https://msil-core.iamdave.ai/'

 export const AppHeader={
    "Content-Type": "application/json",
    "X-I2CE-ENTERPRISE-ID": "maruti_core",
    "X-I2CE-USER-ID": "mpf_admin@mpf.com",
    "X-I2CE-API-KEY": "bXBmX2FkbWluQG1wZi5jb20xNjYyNjQ1MzYxLjkz",
  }

//   export const ApiURL='https://staging.iamdave.ai/'

//  export const AppHeader={
//     "Content-Type": "application/json",
//     "X-I2CE-ENTERPRISE-ID": "maruti_core",
//     "X-I2CE-USER-ID": "ananth+maruti_core@i2ce.in",
//     "X-I2CE-API-KEY": "78e739e1-c90b-373d-8bc3-ea84080b4136",
//   }