import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { InputLabel, IconButton, InputAdornment } from '@mui/material';
import axios from "axios";
import { Formik } from "formik";
import produce from "immer";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import { Logo } from "../../assets/images";

// import { ApiURL, AppHeader } from "../../Components/AppHelpers";
import { ApiURL, AppHeader } from "../../Components/AppHelpers";
// import WithAppContext from "../../Helpers/context/appContext/appContext";
 
function Login(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);  
  const [dealercityLoading, setdealercityLoading] = useState(false);
  const [dealerNameLoading, setDealerNameLoading] = useState(false)
   const [dealerRegionData, setDealerRegionData] = useState(["Loading..."]);
  const [dealerRegion, setDealerRegion] = useState("");
   const [dealerCityData, setDealerCityData] = useState(['Loading...']);
  const [dealerCity, setDealerCity] = useState("");
  const [dealerNameData, setDealerNameData] = useState(['Loading...']);
  const [login, setLogin] = useState(false);
  const [dealerName, setDealerName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const[passowrdMatch,setPasswordMatch]=useState(false)
  const [isDisabled, setIsDisabled] = useState(true);
 
  const [reqErrors, setReqErrors] = useState({
    dealerRegion:"",
     password: "",
     dealerCity:"",
     dealerName:""
  });
   const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }
 

//   let dc = dealerName.split(' - ')[1];
//   console.log(dc, "dc",dealerName);
 
//  useEffect(() => {
//    console.log("Making API Request with dealer_code:", dc);
 
//    axios
//      .get(
//        `${ApiURL}/objects/dealer_paint_formulation?dealer_code=${dc}`,
//        { headers: AppHeader }
//      )
//      .then((res) => {
//        console.log(res, "password");
//      })
//      .catch((error) => {
//        console.error("Error fetching data:", error);
//      });
//  }, []); 
 


  useEffect(() => {
    if (dealerName == "") {
      setIsDisabled(true);
       // console.log(1);
      return;
    } else if (password == "") {
      setIsDisabled(true);
      // console.log(2);

      return;
    } else {
      setIsDisabled(false);
      // console.log(5);

      return;
    }
  }, [dealerName, password]);

  useEffect(() => {
    setIsLoading(true)
     axios
      .get(
        `${ApiURL}pivot/dealer_paint_formulation/dealer_region?_fresh=True&page_size=100`,
        { headers: AppHeader }
      )
      .then((res) => {
        if (res) {
            var delear_region = [];
          var d_r = Object.keys(res.data.data);
          d_r.sort();
           for (var name of d_r) {
            delear_region.push(name);
          }
          setDealerRegionData(delear_region);
          // setDealerLoading("Enter Dealer Region");
          setIsLoading(false);
        }
      });
  }, []);
 
 
  useEffect(() => {
    let logIn = JSON.parse(localStorage.getItem("login"));
    if (logIn != undefined) {
      setLogin(logIn);
    }
  }, []);

  useEffect(() => {
    let logIn = JSON.parse(localStorage.getItem("login"));
    if (logIn != undefined) {
      setLogin(logIn);
      setIsLoading(false);
      if (logIn) {
        navigate("/");
      }
    } else {
      setLogin(false);
      setIsLoading(false);
    }
  }, []);
 
  const dealerRegionName =
  dealerRegion ?? JSON.parse(localStorage.getItem("dealer_region"));
  useEffect(() => {
    if (dealerRegionName === null) {
      // If dealerCity is null, clear the dealer name and disable the button
      setDealerCity("");
      setDealerCityData([]);
      setIsDisabled(true);

    }else{
      setdealercityLoading(true)
      axios
      .get(`${ApiURL}pivot/dealer_paint_formulation/dealer_city?_fresh=True&dealer_region=${dealerRegionName}&page_size=1000`, {
        headers: AppHeader,
      })
      .then((res) => {
        if (res) {
          // console.log(res, "dksbnkja");
          var dealerCityName = res.data.data;
          var d_c = Object.keys(dealerCityName);
          d_c.sort();
       
          setDealerCity("");
          // console.log(d_c, "sahdjash");
          setDealerCityData(d_c);
          setdealercityLoading(false);
        }
      });
    }
  
   
  }, [dealerRegionName]);

   const dealerCityName =
      dealerCity ?? JSON.parse(localStorage.getItem("dealer_city"));
      useEffect(() => {
         if (dealerCityName === null) {
          // If dealerCity is null, clear the dealer name and disable the button
          setDealerName("");
          setDealerNameData([]);
          setIsDisabled(true);
 
        } else {
          setDealerNameLoading(true)
          axios
            .get(
              `${ApiURL}pivot/dealer_paint_formulation/dealer_name/dealer_code?_fresh=True&dealer_city=${dealerCityName}&page_size=1000`,
              { headers: AppHeader }
            )
            .then((res) => {
              if (res) {
                var dealername = [];
                var d_n = res.data.data;
                for (var name in d_n) {
                  for (var code in d_n[name]) {
                    dealername.push(name + " - " + code);
                  }
                }
                 setDealerNameData(dealername);
                setIsDisabled(true); // Disable the button until a dealer name is selected
                setDealerNameLoading(false)
              }
            });
        }
      }, [dealerCityName]);

  useEffect(() => {
    setReqErrors({
      dealerName: "",
      dealerCity: "",
      password:"",
    });
  }, [dealerName, password,dealerCity]);

  const handleSubmit = async () => {
    const errors = { ...reqErrors };
    if(dealerRegion===null || dealerRegion.trim()===""){
      errors.dealerRegion="Dealer Region is required"
      }else{
        errors.dealerRegion=""
      }
    if (dealerName === null || dealerName.trim() === "") {
      errors.dealerName = "Dealer Name is required";
    } else {
      errors.dealerName = ""; 
    }
    
    if (dealerCity === null || dealerCity.trim() === "") {
      errors.dealerCity = "Dealer City is required";
    } else {
      errors.dealerCity = ""; 
    }
    
    if (password === null || password.trim() === "") {
      errors.password = "Password is required";
      setPasswordMatch(false);

    } else {
      errors.password = ""; 
    }
  
   setReqErrors(errors);

  // If there are still errors, don't proceed with the API call 
  if (Object.values(errors).some((error) => error !== "")) {
    return;
  }
      
  
    setIsLoading(true);
      axios
      .get(
        `${ApiURL}/objects/dealer_paint_formulation?dealer_code=${
          dealerName.split(" - ")[1]
        }`,
        { headers: AppHeader }
      )
      .then((res) => {
        if (res) {
          setIsLoading(false);
          let data = res.data.data;

           if (data?.length > 0) {
            if (data[0]?.dealer_password != password) {
              console.log("dealer_password",data[0].dealer_password)
              setPasswordMatch(true)
            } else {
              setPasswordMatch(false);
              localStorage.setItem("login", JSON.stringify(true));
              navigate("/");
            }
 
          } else {
            setReqErrors({
              dealer: "Wrong Dealer Name",
              password: "",
            });
          }

        }
      });
  };

  // console.log("props====", props.context.appLocalData);


  if (!login) {
    return (
      <>
        <img
          src={require("../../assets/bg.png")}
          className="h-[100vh] w-[100vw]"
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: isLoading ? -9 : 0,
          }}
        />
        <Formik
          key={1}
          initialValues={{
            dealer_name: "",
            dealer_city: "",
            password:""
          }}
          // onSubmit={() => {
          //   setLogin(true);
          // }}
    
        >
          {({
            values,
            // errors,
            touched,
            handleBlur,
            // handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <>
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div className="items-center flex flex-col mt-20 ">
                    {/* <img src={Logo} className="w-5/6" /> */}
                    <div className="rounded-lg w-[80%] items-center flex flex-col py-16 bg-white">
                     <div className="w-[80%] text-left uppercase mb-1 mt-1 text-[#9d9fa2] z-0">
                     Dealer Region<span>*</span>
                   </div>
                   <Autocomplete
                      required
                      id="dealer_region"
                      name="dealer_region"
                      options={dealerRegionData}
                      className="w-[80%] bg-[#9d9fa2] "
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiInputBase-input": {
                           cursor: values.dealer_region === "Loading..." ? "not-allowed" : "auto",
                        },
                      }}
                      style={{
                        zIndex: isLoading ? -1 : 1,
                        
                        }}
                      onBlur={handleBlur}
                      value={values.dealer_region}
                      onChange={(event, value) => {
                        if (value === null) {
                          // If dealerCity is null, clear the dealer name and disable the button
                          setDealerCity("");
                          setDealerName("")
                          setDealerNameData([])
                          setDealerCityData([]);
                          setIsDisabled(true);
                          setFieldValue("dealer_city","");
                          setFieldValue("dealer_name","");
                        }else{
                          setDealerCity("");
                          setDealerName("")
                          setDealerNameData([])
                          setDealerCityData([]);
                          setIsDisabled(true);
                          setFieldValue("dealer_city","");
                          setFieldValue("dealer_name","");
                        }
                          setFieldValue("dealer_region", value);
                        // setFieldValue("dealer_name",value);
                        // localStorage.setItem("dealer_name", "");
                        localStorage.setItem(
                          "dealer_region",
                          JSON.stringify(value)
                        );
                        setDealerRegion(value);
                      }}
                      getOptionDisabled={(option) => option === "Loading..."}
                       renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter the Region"
                          disabled={values.dealer_region === "Loading..."}

                        />
                      )}
                    />
                      <div className="text-red-500 w-[80%] text-left z-10 text-sm capitalize">
                      {reqErrors.dealerRegion}
                      </div>
                   <div className="w-[80%] text-left uppercase mb-1 mt-1 text-[#9d9fa2] z-0">
                    Dealer City<span>*</span>
                   </div>
                   <Autocomplete
                        required
                        id="dealer_city"
                        name="dealer_city"
                        options={dealerCityData.map((option) => option)}
                        loading={dealercityLoading}
                        loadingText="Loading..."
                        className="w-[80%] bg-[#9d9fa2] "
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input": {
                            cursor: values.dealer_city === "Loading..." ? "not-allowed" : "auto",
                         },
                        }}
                        style={{
                          zIndex: isLoading ? -1 : 1,
                         }}
                        onBlur={handleBlur}
                        value={values.dealer_city}
                        onChange={(event, value) => {
                          if (value === null) {
                            // If dealerCity is null, clear the dealer name and disable the button
                            setDealerName("");
                            setDealerNameData([]);
                            setIsDisabled(true);
                            setFieldValue("dealer_name","");
                          }else{
                            setDealerName("");
                            setDealerNameData([]);
                            setIsDisabled(true);
                            setFieldValue("dealer_name","");
                          }
                           setFieldValue("dealer_city", value);
                          // setFieldValue("dealer_name",value);
                          // localStorage.setItem("dealer_name", "");
                          localStorage.setItem(
                            "dealer_city",
                            JSON.stringify(value)
                          );
                          setDealerCity(value);
                        }}
                        getOptionDisabled={(option) => option === "Loading..."}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Enter Dealer City"
                            disabled={values.dealer_city === "Loading..."}

                          />
                        )}
                      />
                      <div className="text-red-500 w-[80%] text-left z-10 text-sm capitalize" >
                      {reqErrors.dealerCity}
                      </div>
                      <div className="w-[80%] text-left uppercase mb-1 mt-1 text-[#9d9fa2] z-10 ">
                        DEALER NAME<span>*</span>
                      </div>

                      <Autocomplete
                        required
                        id="dealer_name"
                        name="dealer_name"
                        options={dealerNameData.map((option) => option)}
                        loading={dealerNameLoading}
                        loadingText="Loading..."
                        className="w-[80%] bg-[#9d9fa2]"
                        style={{
                          zIndex: isLoading ? -1 : 1,
                        }}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input": {
                            cursor: values.dealer_name === "Loading..." ? "not-allowed" : "auto",
                         },
                          "& .MuiAutocomplete-input": {
                            whiteSpace: "nowrap",
                            textOverflow: "unset",
                            overflow: "hidden",
                          },

                        }}
                        onBlur={handleBlur}
                        value={values.dealer_name}
                        onChange={(event, value) => {
                          
                           setDealerName(value);
                          setFieldValue("dealer_name",value);
                          localStorage.setItem(
                            "dealer_name",
                            JSON.stringify(value)
                          ); }}
                          getOptionDisabled={(option) => option === "Loading..."}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Enter Dealer Name"
                            disabled={values.dealer_name === "Loading..."}

                          />
                        )}
                      />
                      {/* <TextField
    name="dealer_name"
    id="dealer_name"
    // options={pinCodeData.map((option) => option)}
    value={dealerName}
    className="w-[80%] bg-[#9d9fa2]"
    sx={{
      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    }}
    onBlur={handleBlur}
    placeholder="Enter Dealer Name"
    onChange={(event, value) => {
      // setPinCode(value)
      setDealerName(value);
      // getPinCode(value, 'DealerName')
    }}
  /> */}
                      <div className="text-red-500 w-[80%] text-left z-50 text-sm capitalize">
                      {reqErrors.dealerName}
                      </div>

                      <div className="w-[80%] text-left uppercase mb-1 mt-1 text-[#9d9fa2] z-0">
                        PASSWORD<span>*</span>
                      </div>
                      <TextField
                        name="password"
                        id="password"
                        // options={pinCodeData.map((option) => option)}
                        value={password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                               <IconButton
                                edge="end"
                                 onClick={handleTogglePasswordVisibility}
                                aria-label="toggle password visibility"
                              >
                                {showPassword ?<VisibilityIcon />  : <VisibilityOffIcon />}
                              </IconButton>
                             </InputAdornment>
                          ),
                        }}
                        className="w-[80%] bg-[#9d9fa2]"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                        onBlur={handleBlur}
                        placeholder="Enter Password"
                        type={showPassword?'text':'password'}
                        onChange={(event, value) => {
                          // setPinCode(value)
                          setPassword(event?.target?.value);
                          // getPinCode(value, 'DealerName')
                        }}
                      />
                     <div className="text-red-500 w-[80%] text-left z-50 text-sm capitalize">
                      {/* <span>{reqErrors.password}</span> */}
                      {passowrdMatch ? (<span>Invalid Password!</span>):<span>{reqErrors.password}</span>}
                    </div>
                    <div className="mt-10"> 
                      <Button
                        variant="contained"
                        // type="submit"
                        // disabled={isSubmitting}
                        endIcon={
                          <div className="bg-[#1e3e88] rounded-sm flex items-center absolute top-0 bottom-0 right-0 px-1">
                            <ChevronRightIcon />
                          </div>
                         }
                        onClick={() => {
                          handleSubmit()
                        }}
                          style={{
                          backgroundColor: "#3c5a9f",
                          paddingRight: 50,
                          paddingLeft: 15,
                        }}
                        
                       >
                        Login
                      </Button>
                   
                    </div>
                    <div className="z-10 mt-4 text-center text-sm">
                     <span className=" text-red-600">Note: Oops, Not subscribed!</span><br></br>
                    <span className="text-red-600">Please contact MSIL for support.</span>
                   </div>
                    </div>
                  </div>
                </form>
                </>
            );
          }}
        </Formik>

        {isLoading && (
          <div
            style={{
              width: '100vw',
              height: '100vh',
              display: 'flex',
              backgroundColor: 'white',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 99,
              position: "absolute",
              left: 0,
              top: 0,
            }}
          >
            <CircularProgress />
          </div>
        )}
      </>
    );
  }
}

export default Login;
