import "./infoModal.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faChevronLeft,
  faChevronRight,
  faPause,
  faPlay,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import Webcam from "react-webcam";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import AppLoader from "../../../Components/AppLoader";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import axios from "axios";
import { ApiURL, AppHeader } from "../../../Components/AppHelpers";
import useWindowDimensions from "../../../hooks/windowDimention";
import Loginpop from "../../logoutpopup";
function ScanCarAndInfo({ selectedLang = 0, setLangSubmit, setCanScanTheCar }) {
  const [selected, setSelected] = useState(0);
  const steps = [
    {
      id: 0,
      title: "  KEEP 1FT DISTANCE FROM THE CAR TO TAKE PHOTO.",
      img: "/instructions/1.png",
      // audio: [
      //   "https://s3.ap-south-1.amazonaws.com/www.msilpaintformulation.com/instruction_audio/english_1.wav",
      //   "https://s3.ap-south-1.amazonaws.com/www.msilpaintformulation.com/instruction_audio/hindi_1.wav",
      // ],
      video: [
        "https://mpf-staging.iamdave.ai/videos/V1_English.mp4",
        "https://mpf-staging.iamdave.ai/videos/V1_Hindi.mp4",
        "https://mpf-staging.iamdave.ai/videos/V1_Bengali.mp4",
        "https://mpf-staging.iamdave.ai/videos/V1_gujrati.mp4",
        "https://mpf-staging.iamdave.ai/videos/V1_Telugu.mp4",
        "https://mpf-staging.iamdave.ai/videos/V1_tamil.mp4",
      ],
    },
    {
      id: 1,
      title: "Make sure to not get reflections in the photo.",
      img: "/instructions/2.png",
      // audio: [
      //   "https://s3.ap-south-1.amazonaws.com/www.msilpaintformulation.com/instruction_audio/english_2.wav",
      //   "https://s3.ap-south-1.amazonaws.com/www.msilpaintformulation.com/instruction_audio/hindi_2.wav",
      // ],
      video: [
        "https://mpf-staging.iamdave.ai/videos/V2_English.mp4",
        "https://mpf-staging.iamdave.ai/videos/V2_Hindi.mp4",
        "https://mpf-staging.iamdave.ai/videos/V2_Bengali.mp4",
        "https://mpf-staging.iamdave.ai/videos/V2_gujrati.mp4",
        "https://mpf-staging.iamdave.ai/videos/V2_Telugu.mp4",
        "https://mpf-staging.iamdave.ai/videos/V2_Tamil.mp4",
      ],
    },
    {
      id: 2,
      title: "Click a flat area where the original paint was applied.",
      img: "/instructions/3.png",
      audio: [
        "https://s3.ap-south-1.amazonaws.com/www.msilpaintformulation.com/instruction_audio/english_3.wav",
        "https://s3.ap-south-1.amazonaws.com/www.msilpaintformulation.com/instruction_audio/hindi_3.wav",
      ],
      video: [
        "https://mpf-staging.iamdave.ai/videos/V3_English.mp4",
        "https://mpf-staging.iamdave.ai/videos/V3_Hindi.mp4",
        "https://mpf-staging.iamdave.ai/videos/V3_Bengali.mp4",
        "https://mpf-staging.iamdave.ai/videos/V3_gujrati.mp4",
        "https://mpf-staging.iamdave.ai/videos/V3_Telugu.mp4",
        "https://mpf-staging.iamdave.ai/videos/V3_Tamil.mp4",
      ],
    },
    {
      id: 3,
      title: "Crop the photo to the specific part after clicking the photo.",
      img: "/instructions/4.png",
      audio: [
        "https://s3.ap-south-1.amazonaws.com/www.msilpaintformulation.com/instruction_audio/english_4.wav",
        "https://s3.ap-south-1.amazonaws.com/www.msilpaintformulation.com/instruction_audio/hindi_4.wav",
      ],
      video: [
        "https://mpf-staging.iamdave.ai/videos/V4_English.mp4",
        "https://mpf-staging.iamdave.ai/videos/V4_Hindi.mp4",
        "https://mpf-staging.iamdave.ai/videos/V4_Bengali.mp4",
        "https://mpf-staging.iamdave.ai/videos/V4_gujrati.mp4",
        "https://mpf-staging.iamdave.ai/videos/V4_Telugu.mp4",
        "https://mpf-staging.iamdave.ai/videos/V4_Tamil.mp4",
      ],
    },
  ];
  const [playing, setPlaying] = useState(false);
  const [camera, setCamera] = useState(false);
  const webcamRef = useRef(null);
  const imgRef = useRef(null);
  const blobUrlRef = useRef("");
  const previewCanvasRef = useRef(null);
  const [imageSRC, setImageSRC] = useState();
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSRC(imageSrc);
  }, [webcamRef]);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [showCroppedImage, setShowCroppedImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { mobile } = useWindowDimensions();
  const [progress, setProgress] = useState(0);
  const [totalTime, setTotalTime] = useState(100);
  const [mobileOS, setMobileOS] = useState('unknown');
  const [showPlayButton, setShowPlayButton] = useState(true);


  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    } else if (/android/i.test(userAgent)) {
      return "Android";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
  
    return "unknown";
  }; 

  useEffect(() => {
     setMobileOS(getMobileOperatingSystem());
  }, []);

  // const handlePlayButtonClick = () => {
  //    if (mobileOS == 'iOS') {
  //     document.getElementById("vplay").play();
  //     setShowPlayButton(false)
  //   }
  // };

  const isAndroid = mobileOS === 'Android';
  const isIOS = mobileOS === 'iOS';

  const handleSave = async () => {
    setShowCroppedImage(true);
  };
  const audioRef = useRef(null);

  useEffect(() => {
    audioRef?.current?.play();
    setPlaying(true);
  }, [selected]);

 

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop] 
  );

  const uploadCarPicture = async (files) => {
    const formData = new FormData();
    formData.append("file", files);
    if (files) {
      axios
        .post(`${ApiURL}/upload-file`, formData, { headers: AppHeader })
        .then((res) => {
          if (res) {
            // console.log(res);
            // imageFileList.push(res.data.path)
            // setImageFileList([res.data.path]);
            // console.log("imageFileList===%%%", imageFileList);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          //   console.log("upload-file error ====>", err);
          setIsLoading(false);
        });
    }
  };

  const onCropClick = async () => {
    setIsLoading(true);
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }

    previewCanvasRef.current.toBlob(async (blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);
      //   console.log(blobUrlRef.current);
      try {
        const file = new File([blobUrlRef.current], "File name.png", {
          type: "image/png",
        });
        // console.log(file);
        await uploadCarPicture(file);
      } catch (e) {
        console.log(e);
      }

      //   hiddenAnchorRef.current!.href = blobUrlRef.current
      //   hiddenAnchorRef.current!.click()
    });
  };
  // document.getElementById('vplay').addEventListener('focus', e => {document.getElementById("vplay").play();});

  return (
    < >
      {/* <audio
        key={selected}
        ref={audioRef}
        onEnded={() => {
          setPlaying(false);
          setProgress(0);
          setTotalTime(100);
        }}
        onTimeUpdate={(e) => {
          // console.log(
          //   audioRef?.current?.currentTime,
          //   audioRef?.current?.duration
          // );
          setProgress(audioRef?.current?.currentTime);
          setTotalTime(audioRef?.current?.duration);
        }}
        style={{
          display: "none",
        }}
      >
        <source src={steps[selected]?.audio[selectedLang]} type="audio/wav" />
      </audio> */}
      {isLoading && <AppLoader />}
      <div className={["modal-container    uppercase", mobile && "mob"].join(" ")}>
        {!camera && (
          <>
            <div className={["indicator", mobile && "mob"].join(" ")}>
              {[0, 1, 2, 3].map((i) => {
                return (
                  <div
                    key={i}
                    style={{
                      borderRight: i == 3 ? "0px" : "2px solid grey",
                    }}
                    className={[
                      "indicator-item",
                      selected == i && "selected",
                    ]?.join(" ")}
                  ></div>
                );
              })}
            </div>
            <p className="title-indicator">
              {["DISTANCE", "REFLECTIONS", "ORIGINAL PAINT", "CROP"][selected]}
            </p>
            <div className={["modal-now", mobile && "mob"].join(" ")}>
              <h3>{steps[selected]?.title || ""}</h3>
              <br />
              {/* <img
                src={steps[selected]?.img}
                alt="instructions image"
                className="instructions-image"
              /> */}
              {isIOS ? (
                <>
                  <video
                    className="instructions-image w-80 h-80"
                    id="vplay"
                    controls
                     playsInline
                    key={selected}
                    autoPlay={showPlayButton}
                    onEnded={() => {
                      setPlaying(false);
                      setProgress(0);
                      setTotalTime(100);
                    }}
                    onTimeUpdate={(e) => {
                      setProgress(audioRef?.current?.currentTime);
                      setTotalTime(audioRef?.current?.duration);
                    }}
                   >
                    <source src={steps[selected]?.video[selectedLang]} type="video/mp4" />
                  </video>

                  {/* {showPlayButton&&<div className="play-button-container">
                    <button onClick={handlePlayButtonClick}>
                      Play Video
                    </button>
                  </div>} */}
                </>
              ) : (
                    <video
                key={selected}
                   controls
                autoPlay
                onEnded={() => {
                  setPlaying(false);
                  setProgress(0);
                  setTotalTime(100);
                }}
                className="w-80 h-80"
                // onTimeUpdate={(e) => {
                  // console.log(
                  //   audioRef?.current?.currentTime,
                  //   audioRef?.current?.duration
                  // );
                //   setProgress(audioRef?.current?.currentTime);
                //   setTotalTime(audioRef?.current?.duration);
                // }}
              >
                <source
                  src={steps[selected]?.video[selectedLang]}
                  type="video/mp4"
                />
              </video>
                
              )}
              {/* <div className="audio-file">
                <a
                  onClick={() => {
                    setPlaying(!playing);
                    playing
                      ? audioRef.current.pause()
                      : audioRef.current.play();
                  }}
                  className="play-icon"
                >
                  <FontAwesomeIcon
                    color="white"
                    style={{
                      height: "2.25vh",
                    }}
                    icon={playing ? faPause : faPlay}
                  />
                </a>
                <div className="audio-rack">
                  <div className="audio-line"></div>
                  <div
                    style={{
                      marginLeft:
                        progress > 0 ? `${(progress / totalTime) * 100}%` : 0,
                    }}
                    className="audio-dot"
                  ></div>
                </div>
              </div> */}
            {/**modal ends */}
            {/**modal ends */}
            <div className={["buttonsModal", mobile && "mob"].join(" ")}>
             {[
                {
                  title: "BACK",
                  icon: faChevronLeft,
                  color: "#002883",
                  backColor: "white", // Change 'back' to 'backColor'
                  border: "1px solid #002883",
             
                  onPress: () => {
                    if (selected > 0) {
                      setSelected(selected - 1);
                       setProgress(0);
                      setTotalTime(100);
                      setPlaying(false);
                    } else {
                      setLangSubmit(false);
                    }
                  },
                },
                {
                  title: "NEXT",
                  icon: selected === 3 ? faChevronRight : faChevronRight,
                  color: selected === 3 ? "white" : "#3c5a9f",
                  backColor: selected === 3 ? "#3c5a9f" : "white", // Change 'back' to 'backColor'
                  onPress: () => {
                    if (selected < 3) {
                      setSelected(selected + 1);
                       setProgress(0);
                      setTotalTime(100);
                      setPlaying(false);
                    } else {
                      setCanScanTheCar(true);
                     }
                  },
                },
              ].map((i, index) => {
                return (
                     <button
                      onClick={i?.onPress}
                      style={{
                        backgroundColor: i?.backColor, // Change 'back' to 'backColor'
                        flexDirection: index === 0 ? "row" : "row-reverse",
                        border: "1px solid #002883 ",
                        margin:'10px',
                        marginTop: '20px',
                        borderRadius:"6px",
                        border: i?.border,
                           }}
                      key={i?.title} 
                      className="modalButton"
                    >
                      <FontAwesomeIcon
                        icon={i?.icon}
                        color={i?.color}
                        style={{
                          height: "2vh",
                          marginLeft: mobile ? "1.2vh" : "1vw",
                          marginRight: mobile ? "1.2vh" : "1vw",
                         }}
                      />
                      <p
                        style={{
                          color: i?.color,
                         }}
                      >
                        {i?.title}
                      </p>
                    </button>

                 );
              })}
            </div>
            </div>
            <div className="">
            <Loginpop/>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ScanCarAndInfo;
