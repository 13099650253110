import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import axios from "axios";
import produce from "immer";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import ExitDialog from "../../../Components/ExitDialog";
import { AppHeader, ApiURL } from "../../../Components/AppHelpers";
import TakePhotoDialog from "../../../Components/TakePhotoDialog";
import WithAppContext from "../../../Helpers/context/appContext/appContext";
import { ReactCrop } from "react-image-crop";
import useWindowDimensions from "../../../hooks/windowDimention";
import { useDebounceEffect } from "../infoModals/useDebounceEffect";
import { canvasPreview } from "../infoModals/canvasPreview";
import "../infoModals/infoModal.css";
import { initialValueSet } from "../../../Helpers/context/appContext/app.contexts";
import TakePhotoDialogTwo from "../../../Components/TakePhotoDialogTwo";
const videoConstraints = {
  facingMode: "environment",
};

const TakeCardImage = (props) => {
  const webcamRef = useRef(null);
  const navigate = useNavigate();
  const [isShowCloseModel, setIsShowCloseModel] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isShowTakePhotoDialog, setIsShowTakePhotoDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [imageList, setImageList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [imageFileList, setImageFileList] = useState([]);
  const [allowCrop, setAllowCrop] = useState(false);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const { mobile } = useWindowDimensions();

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setTimeout(() => {
      setOpenModal(false);
    }, 1000);
  };

  useEffect(() => {
    if (props.context.appLocalData) {
      if (props.context.appLocalData.PaintFormulationPhoto.length !== 0) {
        setImageList(props.context.appLocalData.PaintFormulationPhoto);
        props.setCarImage(props.context.appLocalData.PaintFormulationPhoto);
      } else {
        setImageList([]);
        setImageFileList([]);
        props.setCarImage(null);
      }
      setIsLoading(false);
    }
  }, [props.context]);

  const uploadCarPicture = async (files) => {
    const formData = new FormData();
    formData.append("file", files);
    if (files) {
      axios
        .post(`${ApiURL}upload-file`, formData, { headers: AppHeader })
        .then((res) => {
          if (res) {
            imageFileList.push(res.data.path);
            setImageFileList([...imageFileList]);
            if (imageFileList.length >= 3) {
              const nextState = produce(props.context.appLocalData, (draft) => {
                draft.PaintFormulationPhoto = [...imageFileList];
                return draft;
              });
              props.context.setAppLocalData(nextState);
              props.setCarImageFile(imageFileList);
              props.setCarImage(imageList);
              setOpenModal(false);
            }
          }
        })
        .catch((err) => {
          console.log("upload-file error ====>", err);
        });
    }
  };

  const capturePhoto = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    localStorage.setItem("formulation_image1", JSON.stringify(imageSrc));
    // props.setCarImage(imageSrc);
    // const url = imageSrc;
    // fetch(url)
    //   .then((res) => res.blob())
    //   .then((blob) => {
    //     const file = new File([blob], "File name.png", { type: "image/png" });
    //     uploadCarPicture(file);
    //   });

    imageList.push(imageSrc);
    setImageList([...imageList]);
    // if (imageList.length === 3) {
    //   props.setCarImage(imageSrc);
    // }

    setAllowCrop(true);
  }, [webcamRef]);

  const onUserMedia = (e) => {};

  useEffect(() => {
    if (openModal) {
      handleClose();
    }
  }, [openModal]);

  const storeLocalData = () => {
    const paintManufacturer = JSON.parse(
      localStorage.getItem("paintManufacturer")
    );
    const paintCategory = JSON.parse(localStorage.getItem("paintCategory"));
    const color_name = JSON.parse(localStorage.getItem("color_name"));
    let formulation_name = JSON.parse(localStorage.getItem("formulation_name"));
    if (formulation_name != null) {
      formulation_name = formulation_name;
    } else {
      formulation_name = "No name";
    }

    const formulation_image1 = JSON.parse(
      localStorage.getItem("formulation_image1")
    );
    const formulationdetails = JSON.parse(
      localStorage.getItem("formulationdetails")
    );
    var object =
      formulationdetails &&
      formulationdetails.reduce(
        (obj, item) => Object.assign(obj, { [item.label]: item.value }),
        {}
      );
    axios({
      method: "post", //put
      url: `${ApiURL}object/paint_formulation`,
      headers: AppHeader,
      data: {
        formulation_name: formulation_name,
        paint_category_name: paintCategory,
        // colour_id:"Z2S",
        colour_name: color_name,
        tint_codes: object,
        manufacturer_name: paintManufacturer,
        image1: props.carImageFile && props?.carImageFile[0],
        image2: props.carImageFile && props?.carImageFile[1],
        image3: props.carImageFile && props?.carImageFile[2],
      },
    }).then((res) => {
      if (res) {
        navigate("/paint-category");
        window.localStorage.removeItem("formulation_name");
        window.localStorage.removeItem("formulation_image1");
        window.localStorage.removeItem("formulationdetails");
        setIsShowCloseModel();
      }
    });
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  const uploadGalaryCarPicture = async (files) => {
    // console.log("file upload started", `${ApiURL}/upload-file`, formData, { headers: AppHeader });
    const formData = new FormData();
    formData.append("file", files);
    if (files) {
      axios
        .post(`${ApiURL}/upload-file`, formData, { headers: AppHeader })
        .then((res) => {
          if (res) {
            console.log("file upload finished");
            imageList.push(res.data.path);
            setImageList([...imageList]);
            setAllowCrop(true);

            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log("upload-file error ====>", err);
          setIsLoading(false);
        });
    }
  };

  const onSelectOption = (value) => {
    console.log(value.type);
    if (value.type === "Camera") {
      capturePhoto();
    } else {
      if (value.reader) {
        // localStorage.setItem("formulation_image1", JSON.stringify(value.reader));
        // imageList.push(value.reader);
        // uploadCarPicture(value.file);
        // setImageList([...imageList]);

        let tmpPath = (window.URL || window.webkitURL).createObjectURL(
          value.file
        );
        // console.log(tmpPath);

        setIsLoading(true);
        uploadGalaryCarPicture(value.file);
      }
    }
  };

  const onCropClick = async () => {
    setIsLoading(true);
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }

    previewCanvasRef.current.toBlob(async (blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      // if (blobUrlRef.current) {
      //   URL.revokeObjectURL(blobUrlRef.current);
      // }
      // blobUrlRef.current = URL.createObjectURL(blob);
      //   console.log(blobUrlRef.current);
      try {
        const file = new File([blob], "File name.png", {
          type: "image/png",
        });
        console.log(file);
        await uploadCarPicture(file);
        setIsLoading(false);
        setAllowCrop(false);
      } catch (e) {
        console.log(e, "klkk");
      }

      //   hiddenAnchorRef.current!.href = blobUrlRef.current
      //   hiddenAnchorRef.current!.click()
    });
  };

  const withoutCrop = async () => {
    setIsLoading(true);
    let data = await fetch(imageList[imageList.length - 1]);
    let blob = await data.blob();
    try {
      const file = new File([blob], "File name.png", {
        type: "image/png",
      });
      console.log(file);
      await uploadCarPicture(file);
      setIsLoading(false);
      setAllowCrop(false);
    } catch (e) {
      console.log(e);
    }
  };

  if (isLoading) {
    return (
      <div className="h-full w-full justify-center items-center flex">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="w-full bg-white h-full">
      <div className="justify-end flex m-1">
        {/* <div
          className="bg-[red] rounded-md"
          onClick={() => {
            setIsShowCloseModel(true);
          }}
        >
          <IconButton aria-label="delete">
            <CloseIcon sx={{ color: "#fff" }} />
          </IconButton>
        </div> */}
      </div>
      <div className="flex mt-8 items-center h-full w-full flex-col">
        <div className="mb-4 font-bold uppercase">Take Shade Card Image</div>
        <div className="w-[90%] h-[75%] rounded-2xl overflow-hidden">
          {props.carImage ? (
            <div className="flex justify-center bg-black h-full">
              <img
                src={props.carImage[props.carImage.length - 1]}
                alt="Screenshot"
                className="object-contain"
              />
            </div>
          ) : (
            <div className="h-full w-full">
              {allowCrop ? (
                <>
                  <ReactCrop
                    aspect={1}
                    className={["cropImage", mobile && "mob"].join(" ")}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => setCompletedCrop(c)}
                  >
                    <img
                      ref={imgRef}
                      key={imageList.length}
                      src={imageList[imageList.length - 1]}
                      crossOrigin="anonymous"
                      alt="Screenshot"
                      className="cropIMG"
                    />
                  </ReactCrop>
                  <div
                    style={{
                      display: "none",
                      overflow: "hidden",
                    }}
                    className="cropper"
                  >
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        // border: "1px solid black",
                        objectFit: "contain",
                      }}
                      // className="max-h-full"
                    />
                  </div>
                </>
              ) : (
                <Webcam
                  ref={webcamRef}
                  audio={false}
                  screenshotFormat="image/png"
                  videoConstraints={videoConstraints}
                  onUserMedia={onUserMedia}
                  mirrored={false}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              )}

              {imageList.length < 4 && (
                <div className="absolute right-0 left-0 justify-center flex -mt-24">
                  <Button
                    variant="contained"
                    endIcon={
                      <div className="bg-[#1e3e88] flex items-center absolute top-0 bottom-0 right-0 px-2">
                        <CenterFocusWeakIcon />
                      </div>
                    }
                    sx={{
                      backgroundColor: "#3c5a9f",
                      paddingRight: 6,
                    }}
                    // disabled={allowCrop ? completedCrop == undefined : false}
                    onClick={() => {
                      if (allowCrop) {
                        if (completedCrop) {
                          onCropClick();
                        } else {
                          withoutCrop();
                        }
                      } else {
                        setCrop(undefined);
                        setCompletedCrop(undefined);
                        setIsShowTakePhotoDialog(true);
                      }
                    }}
                  >
                    {allowCrop
                      ? completedCrop
                        ? `Crop Photo ${imageList.length}`
                        : `Save & Take the Next Photo`
                      : `Take Photo ${imageList.length + 1}`}
                  </Button>
                </div>
              )}
              {imageList.length > 3 && (
                <Button
                  variant="contained"
                  endIcon={
                    <div className="bg-[#1e3e88] flex items-center absolute top-0 bottom-0 right-0 px-2">
                      <CenterFocusWeakIcon />
                    </div>
                  }
                  sx={{
                    backgroundColor: "#3c5a9f",
                    paddingRight: 6,
                  }}
                  onClick={() => {
                    onCropClick();
                  }}
                >
                  Crop Photo {imageList.length}
                </Button>
              )}
            </div>
          )}
        </div>
        <div className=" flex justify-between w-[80%] mt-6 sm:flex sm:flex-row flex-col">
          <div className="w-full sm:w-1/4 mb-2">
            <Button
              className="w-full mt-2"
              onClick={() => {
                props.setIsActiveWindow(props.isActiveWindow - 1);
                props.context.setAppLocalData(initialValueSet);
              }}
              variant="outlined"
              startIcon={<ChevronLeftIcon />}
              sx={{
                color: "#002783",
                border: "1px solid #002783",
              }}
            >
              Back
            </Button>
          </div>
          <div className="w-full sm:w-1/4 mb-2">
            {/* {alert(props.carImageFile.length)} */}
            <Button
              className="w-full mt-2"
              disabled={props.carImageFile?.length < 3}
              onClick={() => {
                if (props.isActiveWindow === 3) {
                  if (props.carImage) {
                    props.setIsActiveWindow(props.isActiveWindow + 1);
                  } else {
                    handleClickOpen();
                  }
                } else {
                  props.setIsActiveWindow(props.isActiveWindow + 1);
                }
              }}
              variant="outlined"
              endIcon={<ChevronRightIcon />}
              sx={{
                color: "#002783",
                border: "1px solid #002783",
              }}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        open={openModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="text-red-500">
          Car Image is Required
        </DialogTitle>
      </Dialog>
      <ExitDialog
        isShowCloseModel={isShowCloseModel}
        setIsShowCloseModel={() => storeLocalData()}
        setIsShowCloseModelOnly={() => setIsShowCloseModel(false)}
        isActiveWindow={props.isActiveWindow}
        setIsActiveWindow={props.setIsActiveWindow}
      />
      <TakePhotoDialogTwo
        isShowTakePhotoDialog={isShowTakePhotoDialog}
        setIsShowTakePhotoDialog={setIsShowTakePhotoDialog}
        onSelectOption={onSelectOption}
      />
    </div>
  );
};

export default WithAppContext(TakeCardImage);
