import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 340,
  bgcolor: 'rgba(69, 69, 69, 0.7)',
  boxShadow: 24,
  p: 4,
color:'white',

 };

 const buttonStyle = {
  background: 'white', 
  color: 'blue', 
  margin: '8px',
  fontFamily: 'font-Oxanium',
  fontWeight: 'bold',
  fontSize:"10px"
};
 const logbtn = {
  color: "gray",
  backgroundColor: "transparent",
  '&:hover': {
    color: "black",
     backgroundColor: "lightgray",  
     fontWeight: 'bold'

  },
}
export default function Loginpop() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate=useNavigate()

  return (
    <div>
      <Button onClick={handleOpen}  endIcon={<LogoutIcon />} sx={logbtn} >Logout</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" className='' sx={{ mt: 2 }} style={{fontFamily:"font-Oxanium"}}>
            Are you sure you want to logout?
           </Typography>
           <Button  onClick={handleClose} className=''  sx={buttonStyle}>Cancel</Button> 
            <Button sx={buttonStyle} className='' endIcon={<LogoutIcon style={{width:"12px"}}/>} onClick={()=>{
            localStorage.clear();
            navigate('/log-in')
           }}>Logout</Button>
         </Box>
      </Modal>
    </div>
  );
}
