import React, { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import { AppHeader } from './AppHelpers';
import { useRef } from 'react';
import { ApiURL } from './AppHelpers';
import CircularProgress from "@mui/material/CircularProgress";
import Paint_Consumption_Report from './Paint_Consumption_Report';
 function Paint_consumption({ formulationdetails, onHide, apprxDistance, usedPredictedData, onConfirmPress,toggleLoading }) {

  const [dropdown, setDropdown] = useState(false)
  const [dropdownData, setDropdownData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
   const [error, setError] = useState('')
  const [panelName, setPanelname] = useState(() => {
    const storedPanelName = JSON.parse(localStorage.getItem("panelName"));
    return storedPanelName || [];
  });

  const [panelOptions, setPanelOptions] = useState(() => {
    const storedPanelName = JSON.parse(localStorage.getItem("panelOptions"));
    return storedPanelName || [];
  });

  const [selectedData, setSelectedData] = useState(() => {
    const storeSelectedData = JSON.parse(localStorage.getItem("selectedData"));
    return storeSelectedData || [];
  });

  const [repair_values, setRepairValues] = useState(() => {
    const storeSelectedData = JSON.parse(localStorage.getItem("repair"));
    return storeSelectedData || [];
  });
  const [replace_values, setReplaceValues] = useState(() => {
    const storeSelectedData = JSON.parse(localStorage.getItem("replace"));
    return storeSelectedData || [];
  });

   const [EditTabel, setEditTable] = useState(false)
  const [showTable, setShowTable] = useState(false)
  const [closeIcon,setCloseIcon]=useState(false)
  const [fetchedPanelCodes, setFetchedPanelCodes] = useState([]);
  const [selectedRepairIndex, setSelectedRepairIndex] = useState(null);  
  const percentageRef = useRef(null);
  const paintCategory = JSON.parse(localStorage.getItem("paintCategory"));
  const manufacturer_name = JSON.parse(localStorage.getItem("paintManufacturer"));
  const vehicle_model = JSON.parse(localStorage.getItem("vehicle_model"));
  //  api for the panel_code
  useEffect(() => {
    fetchData();
  }, []);
  let page_size = 50
  let pageNumber = 1
  function fetchData() {
    setIsLoading(true)
    axios.get(`${ApiURL}pivot/pf_options/option?category=panel_code_without_space&_fresh=True&page_size=${page_size}&page_number=${pageNumber}`,
      { headers: AppHeader }
    ).then(response => {
      let data = response.data.data;
      if (data) {
        let newDataArray = Object.entries(data).map(([panelCode, value]) => ({
          panelCode,
          value
        }));
        setDropdownData(newDataArray)
        if (!response.data.is_last) {
          pageNumber++;
          fetchData();
        } else {
          setIsLoading(false)
        }
      } else {
        console.log("No data found");
      }
    }).catch(error => {
      console.error('Axios error:', error);
      setIsLoading(false)
    });
  }

 
function getValues(panelCode) {
  console.log(panelCode,"panelcode");
  toggleLoading(true);

  axios.get(`${ApiURL}objects/paint_consumption?panel_code=${panelCode}&paint_category=${paintCategory}&manufacturer_name=${manufacturer_name}&vehicle_model=${vehicle_model}`,
    { headers: AppHeader }
  ).then(response => {
    let data = response.data.data;
    if (data && data.length > 0) {
      setReplaceValues(prevReplaceValues => [...prevReplaceValues, data[0].replacement_panel_value]);
      setRepairValues(prevRepairValues => [...prevRepairValues, data[0].repair_panel_value]);
    } else {
      console.log("no data found");
    }
    
    setTimeout(() => {
      toggleLoading(false);
    }, 2000);

  }).catch(error => {
    console.error('Axios error:', error);
    toggleLoading(false);
  });
}

  const handlePanelUnselect = (panelCode) => {
     setPanelname(panelName.filter(panel => panel !== panelCode));
  
     setReplaceValues(prevReplaceValues => prevReplaceValues.filter((_, index) => panelName[index] !== panelCode));
    setRepairValues(prevRepairValues => prevRepairValues.filter((_, index) => panelName[index] !== panelCode));
  
     setSelectedData(prevSelectedData => prevSelectedData.filter(data => data && data.panelCode !== panelCode));
  
     setPanelOptions(prevPanelOptions => prevPanelOptions.filter((_, index) => panelName[index] !== panelCode));
  
     setFetchedPanelCodes(prevFetchedPanelCodes => prevFetchedPanelCodes.filter(code => code !== panelCode));
  
   };
  
  // Function to handle panel selection
  const handlePanelSelect = (panelCode) => {
    if (panelName.includes(panelCode)) {
      handlePanelUnselect(panelCode);
    } else {
      getValues(panelCode)
      setPanelname([...panelName, panelCode]);
    }
  }
  console.log(fetchedPanelCodes, "fetchedPanelCodes")
  // Function to handle checkbox change
  
  const handleCheckboxChange = (panelIndex, optionIndex) => {
     const updatedPanelOptions = [...panelOptions];
     setSelectedRepairIndex(panelIndex)
    updatedPanelOptions[panelIndex] = optionIndex;
    setPanelOptions(updatedPanelOptions);
     if (selectedData.length === 0) {
      const initialData = panelName.map((panelCode, index) => ({
        panelCode,
        option: -1,
        percentage: 0
      }));
      setSelectedData(initialData);
    }

    // Update selected data
    const updatedSelectedData = [...selectedData];
    updatedSelectedData[panelIndex] = {
      panelCode: panelName[panelIndex],
      option: optionIndex,
      percentage: 0
    };
    setSelectedData(updatedSelectedData);

    localStorage.setItem('panelOptions', JSON.stringify(updatedPanelOptions));
    localStorage.setItem('selectedData', JSON.stringify(updatedSelectedData));
  };

  // Function to handle repair type selection
  const handleRepairType = (panelIndex, percentage) => {
    const updatedSelectedData = [...selectedData];
    if(updatedSelectedData[panelIndex]){
      updatedSelectedData[panelIndex].percentage = percentage;
      setSelectedData(updatedSelectedData);
    }else{
      setError("select All the Check Box")
     }
   
  };
  useEffect(() => {
    const storedPanelOptions = JSON.parse(localStorage.getItem('panelOptions'));
    if (storedPanelOptions) {
      setPanelOptions(storedPanelOptions);
    }

    const storedSelectedData = JSON.parse(localStorage.getItem('selectedData'));
    if (storedSelectedData) {
      setSelectedData(storedSelectedData);
    }
  }, []);

  // Calculate the total based on selected data
 
  const handleError = () => {
    let hasError = false;

    const nullExists = selectedData.some(item => item === null||item==='empty');
    const indicesSequential = selectedData.every((_, index) => index in selectedData);

   
    const panelCodes = selectedData.filter(item => item && item.panelCode).map(item => item.panelCode);

     const allPanelNamesPresent = panelName.every(name => panelCodes.includes(name));


    if (!allPanelNamesPresent) {
        setError("Please Select All the Check box.");
        hasError = true;
    } else {
        selectedData.forEach((item) => {
            if (item&&item.option === 1 && item.percentage === 0) {
                setError("Kindly Select the Consumption Percentage");
                hasError = true;
            }
        });

        if (!hasError) {
            if (panelName.length === 0) {
                setError("Kindly Select at Least One Panel");
                hasError = true;
            } else if (selectedData.length === 0) {
                setError("Kindly Select Repair/Replace Value");
                hasError = true;
            } else if (selectedData.length !== panelName.length) {
                setError("Please Select Panel Values");
                hasError = true;
            } else {
                setError("Calculated value Shown Below");
            }
        }
    }

    setShowTable(!hasError);
    setEditTable(!hasError);
};

  
 
  const handleCalculatingVolume = () => {
    handleError();
    setCloseIcon(false)
    let total = 0;
    Object.values(selectedData).forEach((item, index) => {
      // Check if item is not null or undfined before accessing its properties
      if (item && item.option === 1) {
        total += (repair_values[index] * item.percentage) / 100;
      } else if (item && item.option === 0) {
        total += replace_values[index];
      }
    });
  
    localStorage.setItem("volume", total);
    console.log(total, "total");
  };
  


  useEffect(() => {
    localStorage.setItem("panelName", JSON.stringify(panelName));
  }, [panelName]);

  useEffect(() => {
    const storedPanelName = JSON.parse(localStorage.getItem('panelName'));
    if (storedPanelName) {
      setPanelname(storedPanelName);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedData", JSON.stringify(selectedData));
  }, [selectedData]);

  useEffect(() => {
    localStorage.setItem("repair", JSON.stringify(repair_values));
  }, [repair_values])

  useEffect(() => {
    localStorage.setItem("replace", JSON.stringify(replace_values));
  }, [replace_values])

  useEffect(() => {
    localStorage.setItem("panelOptions", JSON.stringify(panelOptions));
  }, [panelOptions])


  const handleConsumptionModel = () => {
    console.log("heelo")
     setEditTable(true)
     setCloseIcon(false)

   }

const handleCloseIcon=()=>{
  setEditTable(false)
  setCloseIcon(true)
}
  console.log(error)

  return (

    <div className='  font-Oxanium uppercase '>
      <div className=''>
        </div>
       <div className='flex justify-between bg-white py-2  rounded-md mt-2 '>
        <h1 className=' font-semibold font-Oxanium uppercase text-xs mt-2 lg:text-base md:text-sm ml-2 ' >CALCULATE PAINT CONSUMPTION</h1>
        {EditTabel&& <button className='bg-[#002783] h-8  mr-1 rounded-md  text-white px-4 text-xs   lg:text-sm md:text-sm' onClick={handleCloseIcon}>EDIT</button> }
        {closeIcon&&  <div className='bg-red-500 rounded-md p-1 mr-2 '>
            <CloseIcon style={{ fill: "white" }} onClick={handleConsumptionModel} />
          </div>}
       </div>
      {/* //Main table */}
      {EditTabel ?
        (
          <div className='Editing-table lg:text-base'>
            <div>
              <div className='flex'>
              <h1 className='w-[100px] text-xs  lg:w-1/3 mr-2 mt-2 bg-gray-200 p-2 text-blue-800  uppercase rounded-md'>Panel Code</h1>
                <h1 className='w-[68%]  flex-shrink lg:w-[68%] md:w-[68%] sm:w-[68%] text-xs mt-2   sm:text-md  bg-gray-200 p-2 text-blue-800 rounded-md '>Repair Type</h1>
              </div>
              {panelName?.map((panel, index) => (
                <div className="flex-shrink-0 overflow-auto flex mt-2" key={index} disabled={true}>
                  <div className='w-36 lg:w-1/2 md:w-1/2  sm:w-1/2 bg-white rounded-md '>
                    <h1 className='  lg:w-full md:w-full sm:w-full overflow-auto text-[12px] lg:text-sm  py-2 mt-2 bg-white text-blue-900  gap-4'>{panel}</h1>
                  </div>
                  <div className=' overflow-auto rounded-md w-full  bg-white text-[10px]  ml-1  lg:text-base sm:text-md  '>
                    <div className='lg:flex md:flex '>
                    <div className='p-2 mt-2 text-[12px] lg:text-sm flex md:flex-row lg:gap-20 lg:flex-row justify-between  '>
                      
                      <div className="flex items-center ">
                        <input
                          type='checkbox'
                          className='mb-1'
                          checked={panelOptions[index] === 1}
                          onChange={() => handleCheckboxChange(index, 1)}
                          readOnly
                          disabled={true}

                        />
                        <span className="text-blue-900 ml-1 mr-2 text-[10px] lg:gap-20  lg:text-sm md:text-sm sm:text-sm" disabled={panelOptions[index] === 0}>Repair</span>
                      </div>
                      <div className="flex items-center">
                        <input
                          type='checkbox'
                          className='mb-1 ml-2'
                          checked={panelOptions[index] === 0}
                          onChange={() => handleCheckboxChange(index, 0)}
                          readOnly
                          disabled={true}

                        />
                        <span className="text-blue-900 ml-1  text-[10px] lg:gap-20  lg:text-sm md:text-sm sm:text-sm" disabled={panelOptions[index] === 0}>Replace</span>
                      </div>
                      
                    </div>

                    {/* percentae */}
                    <div className='flex ml-2  text-[10px] lg:gap-20  lg:text-sm md:text-sm sm:text-sm mt-2 lg:ml-20'>
                     <>
                     <div className="flex items-center">
                          <input
                            type='radio'
                            className='mb-1 mr-2'
                            name={`repairType-${index}`}
                            disabled={true}
                            checked={selectedData[index]?.percentage === 25}
                            onClick={(e) => handleRepairType(index, 25)} />
                          <span className={`mb-1 mr-4 ${panelOptions[index] === 0 ? 'pointer-events-none text-gray-500' : 'text-blue-900'}`}>25%</span>
                        </div><div className="flex items-center">
                            <input
                              type='radio'
                              className='mb-1 mr-2'
                              name={`repairType-${index}`}
                              checked={selectedData[index]?.percentage === 50}
                              disabled={true}
                              onClick={(e) => handleRepairType(index, 50)} />
                            <span className={`mb-1 mr-4 ${panelOptions[index] === 0 ? 'pointer-events-none text-gray-500' : 'text-blue-900'}`}>50%</span>
                          </div><div className="flex items-center">
                            <input
                              type='radio'
                              className='mb-1 mr-2'
                              name={`repairType-${index}`}
                              disabled={true}
                              checked={selectedData[index]?.percentage === 75}
                              onClick={(e) => handleRepairType(index, 75)} />
                            <span className={`mb-1 mr-4 ${panelOptions[index] === 0 ? 'pointer-events-none text-gray-500' : 'text-blue-900'}`}>75%</span>
                          </div>
                          </>
                   

                      </div>

                      </div>

                   </div>
                </div>
              ))}
            </div>
            <div className='flex justify-between  mt-2'>
              <div className='text-xs mt-2'>
                <InfoIcon  className='mr-2' style={{ fontSize: '16px' }}/><span className='text-[#777777] text-[9px] lg:text-base md:text-sm sm:text-sm'>{error}</span>
              </div>
            
            </div>
          </div>
        ) : (
          <div className='main-table'>
            <div className=' bg-white p-2 dropdown flex justify-between text-xl mt-4 rounded-md' >
              <h4 className='font-Oxanium uppercase font-normal text-xs lg:text-sm md:text-sm sm:text-sm mt-2 ' onClick={() => setDropdown(true)}>Choose {vehicle_model} Panels</h4>
              <>
                {dropdown ? (
                  <button onClick={() => setDropdown(false)}>
                    <CloseIcon />
                  </button>) : (
                  <button onClick={() => setDropdown(true)}>
                    <ExpandMoreIcon />
                  </button>
                )
                }
              </>
            </div>
            {dropdown &&
              <div className='flex flex-col h-32  overflow-auto '>
                <div>
                  <div className='flex justify-center items-center '>
                    {isLoading && <CircularProgress />}
                  </div>
                  {dropdownData.map((item, index) => (
                    <div key={index} className='check mt-2  flex flex-row'>
                      <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 items-center text-indigo-600"
                        id={`checkbox-${index}`}
                        onChange={() => handlePanelSelect(item.panelCode)}
                        checked={panelName.includes(item.panelCode)}
                        readOnly
                      />
                      <label htmlFor={`checkbox-${index}`} className="ml-2 text-[#2D3494] text-sm">{item.panelCode}</label>
                    </div>
                  ))}
                </div>
              </div>}
            <div>
            <div className='flex lg:text-base'>
                <h1 className='w-[100px] text-xs  lg:w-1/3 mr-2 mt-2 bg-[#DCE0E9] p-2 text-blue-800  uppercase rounded-md'>Panel Code</h1>
                <h1 className='w-[68%]  lg:w-[68%] md:w-[68%] sm:w-[68%] text-xs mt-2   sm:text-md  bg-[#DCE0E9] p-2 text-blue-800 rounded-md '>Repair Type</h1>
              </div>
              {panelName?.map((panel, index) => (
                <div className="flex-shrink-0 overflow-auto flex mt-2" key={index} disabled={true}>
                  <div className='w-36 lg:w-1/2 md:w-1/2  sm:w-1/2 bg-white rounded-md '>
                  <h1 className='  lg:w-full md:w-full sm:w-full overflow-auto text-[10px] lg:text-sm  py-2 mt-2  text-blue-900  '>{panel}</h1>
                  </div>
                  <div  className=' overflow-auto rounded-md w-full  bg-white text-[10px]  ml-1  lg:text-base sm:text-md'>
                    <div className='lg:flex md:flex '>
                    <div className='p-2 mt-2 text-[12px] lg:text-sm flex md:flex-row lg:gap-20 lg:flex-row justify-between  '>
                      
                      <div className="flex items-center ">
                        <input
                          type='checkbox'
                          className='mb-1'
                          checked={panelOptions[index] === 1}
                          onChange={() => handleCheckboxChange(index, 1)}
                          readOnly
                        />
                        <span className="text-blue-900 ml-1 mr-2 text-[10px] lg:gap-20  lg:text-sm md:text-sm sm:text-sm" disabled={panelOptions[index] === 0}>Repair</span>
                      </div>
                      <div className="flex items-center">
                        <input
                          type='checkbox'
                          className='mb-1 ml-2'
                          checked={panelOptions[index] === 0}
                          onChange={() => handleCheckboxChange(index, 0)}
                          readOnly
                        />
                        <span className="text-blue-900 ml-1 mr-2 text-[10px] lg:gap-20  lg:text-sm md:text-sm sm:text-sm" disabled={panelOptions[index] === 0}>Replace</span>
                      </div>
                      <div className='   lg:hidden md:hidden sm:hidden'>
                      <DeleteForeverIcon className=' ' style={{ fill: "red",width:"20px",marginBottom:"2px"}} onClick={() => handlePanelUnselect(panel)} />
                        </div>
                    </div>

                    {/* percentae */}
                    <div className='flex ml-2  text-[10px] lg:gap-20  lg:text-sm md:text-sm sm:text-sm mt-2 lg:ml-20'>
                   {panelOptions[index] === 1?
                    <div className='flex justify-between'ref={percentageRef}  >
                     <div className="flex items-center">
                          <input
                            type='radio'
                            className='mb-1 mr-2'
                            name={`repairType-${index}`}
                            disabled={panelOptions[index] === 0}
                            checked={selectedData[index]?.percentage === 25}
                            onClick={(e) => handleRepairType(index, 25)} />
                          <span className={`mb-1 mr-4 ${panelOptions[index] === 0 ? 'pointer-events-none text-gray-500' : 'text-blue-900'}`}>25%</span>
                        </div><div className="flex items-center">
                            <input
                              type='radio'
                              className='mb-1 mr-2'
                              name={`repairType-${index}`}
                              checked={selectedData[index]?.percentage === 50}
                              disabled={panelOptions[index] === 0}
                              onClick={(e) => handleRepairType(index, 50)} />
                            <span className={`mb-1 mr-4 ${panelOptions[index] === 0 ? 'pointer-events-none text-gray-500' : 'text-blue-900'}`}>50%</span>
                          </div><div className="flex items-center">
                            <input
                              type='radio'
                              className='mb-1 mr-2'
                              name={`repairType-${index}`}
                              disabled={panelOptions[index] === 0}
                              checked={selectedData[index]?.percentage === 75}
                              onClick={(e) => handleRepairType(index, 75)} />
                            <span className={`mb-1 mr-4 ${panelOptions[index] === 0 ? 'pointer-events-none text-gray-500' : 'text-blue-900'}`}>75%</span>
                          </div>
                          </div>
                          :
                          null
                          }
                      <div className=' hidden  lg:inline md:inline sm:inline'>
                      <DeleteForeverIcon className=' ' style={{ fill: "red",width:"20px"}} onClick={() => handlePanelUnselect(panel)} />
                         </div>

                      </div>

                      </div>

                   </div>

                </div>

              ))}
            </div>

            <div className='flex justify-between w-full mt-2'>
              <div className='mt-4'>
                {error ? <><InfoIcon className='text-xs mr-2' style={{ fontSize: '16px' }}/><span className='text-[#777777] text-[9px] lg:text-base md:text-sm sm:text-sm'>{error}</span></> :<><InfoIcon className=' text-xs mr-2' style={{ fontSize: '16px' }}/><span className=' text-[#777777] text-[9px] lg:text-base md:text-sm sm:text-sm'>Kindly select atleast one panel</span></>}
              </div>
              {panelName.length === 0  ? <button className=' bg-gray-400 h-8 mt-2  rounded-md p-1 text-white px-2 text-xs lg:text-sm md:text-sm sm:text-sm' disabled={true} onClick={handleCalculatingVolume}>CALCULATE</button> : <button className='bg-[#002783] h-8 mt-2  rounded-md p-1 text-white px-2 text-xs lg:text-sm md:text-sm sm:text-sm' onClick={handleCalculatingVolume}>CALCULATE</button>}
            </div>
          </div>
        )}

      {panelName.length > 0 && showTable && (
        <Paint_Consumption_Report
          formulationDetails={formulationdetails}
          apprxDistance={apprxDistance}
          usedPredictedData={usedPredictedData}
          onConfirmPress={onConfirmPress}
        />
      )}


    </div>
  )
}

export default Paint_consumption




