import { Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import axios from "axios";
import React, { useEffect, useState } from "react";
import  { ApiURL } from "./AppHelpers";
import { AppHeader } from "./AppHelpers";
import AppLoader from "./AppLoader";
 

export default function PaintFormulation(props) {
  const [isLoading, setIsLoading] = useState(false);
  const[tintLoading,setTintLoading]=useState(true)
  const [isHideDesiredColourFormulation, setIsHideDesiredColourFormulation] =
    useState(false); 
    const[volume,setVolume]=useState("")
    const [isTableEmpty, setIsTableEmpty] = useState(false);
 
  useEffect(() => {
    const formulation_id = JSON.parse(
      localStorage.getItem("found_formulation_id")
    );
 
    if (formulation_id != null) {
      try {
        setIsLoading(true)
        setTintLoading(true);
        axios
          .get(
            `${ApiURL}objects/paint_formulation?original_paint_formulation=false&formulation_id=${formulation_id}`,
            { headers: AppHeader }
          )
          .then((res) => {
            if (res) {
               var form_details = [];
              const d = res.data.data[0];
              setVolume(d.volume)
              // console.log("dd", d.volume)
              if (d.volume == "1 Litter" || d.volume == "1 litre") {
                 for (let key in d.tint_codes) {
                  form_details.push({
                    label: key,
                    value: (d.tint_codes[key]).toFixed(1),
                  });
                }
                props.setFormulationDetails(form_details);
                props.setDefaultFormulationDetails(form_details);
                props.setIsLoading(false);
              } else {
                 for (let key in d.tint_codes) {
                  form_details.push({ 
                    label: key,
                    value: d.tint_codes[key],
                  });
                }
                setIsLoading(false)
                setTintLoading(false);
                props.setIsLoading(false);
                props.setFormulationDetails(form_details);
                props.setDefaultFormulationDetails(form_details);
              }
            }
          });
      } catch (error) {
        props.setIsLoading(false);
      }
    } else {
       // props.setIsHideDesiredColourFormulation(true)
 
      props.setIsLoading(false);
    }
  }, []);

   const onSelectTintsValue = (index, value) => {
    props.defaultFormulationDetails[index] = {
      ...props.defaultFormulationDetails[index],
      label: value,
    };

    props.setDefaultFormulationDetails([...props.defaultFormulationDetails]);
    props.setIsTableEmpty(false)
  };

  const onSelectTintsVolume = (index, value) => {
    props.defaultFormulationDetails[index] = {
      ...props.defaultFormulationDetails[index],
      value: value,
    };
    props.setDefaultFormulationDetails([...props.defaultFormulationDetails]);
    props.setIsTableEmpty(false);
  };

  useEffect(() => {
    // Display the loader initially
    const loaderTimeout = setTimeout(() => {
      setTintLoading(false);
    }, 3000);  

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(loaderTimeout);
  }, []);
  useEffect(() => {
    // Check if the table values are empty
    const isEmpty = props.defaultFormulationDetails?.every(item => item.label === "" || item.value === "");
    props.setIsTableEmpty(isEmpty);
  }, [props.defaultFormulationDetails]);

 const addtint= () => {
    const data = {
      label: "Q065",
      value: "22.169",
    };
     props.setDefaultFormulationDetails([...props.defaultFormulationDetails,data]);
   }

  return (
    <div>
      {props.isEditTableValue ? (
        <>
       {/* {tintLoading&&<AppLoader/>} */}
          <TableContainer component={Paper} style={{marginTop:4,borderRadius:'10px'}}>
            <Table  aria-label="caption table">
              <TableHead>
                <TableRow className="bg-[#cad1e1]">
                  <TableCell align="center">Tints</TableCell>
                  <TableCell align="center">Tint Volume {(volume)}</TableCell>
                  {props.isEditTableValue && (
                    <TableCell align="center">Actions</TableCell>
                  )}
                  {/* <TableCell align="center">Tint Volume (100 gm)</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.defaultFormulationDetails?.length !== 0 &&
                  props.defaultFormulationDetails?.map((item, index) => (
                    <TableRow key={index}>
                      {props.isEditTableValue ? (
                        <TableCell width="50%" align="center">
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            value={item.label}
                            className="items-center"
                            InputProps={{
                              sx: {
                                "& .MuiInput-input": {
                                  padding: 0,
                                  textAlign: "center",
                                },
                              },
                              disableUnderline: true,
                              style: {
                                padding: 0,
                              },
                            }}
                            placeholder="Enter Tints"
                            onChange={(event) => {
                              onSelectTintsValue(index, event.target.value);
                            }}
                          />
                        </TableCell>
                      ) : (

                        null
                      )}
                      {props.isEditTableValue ? (
                        <TableCell width="50%" align="center">
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          value={item.value}
                          type="text"
                          className="items-center"
                          InputProps={{
                            sx: {
                              "& .MuiInput-input": {
                                padding: 0,
                                textAlign: "center",
                              },
                            },
                            disableUnderline: true,
                            style: {
                              padding: 0,
                            },
                            step: 'any',
                            pattern: '^[0-9]*[.]?[0-9]*$',
                          }}
                          placeholder="Enter Tint Volume"
                          onChange={(event) => {
                            let inputValue = event.target.value.replace(/[^0-9.]/g, ''); 
                            const decimalCount = (inputValue.match(/\./g) || []).length;
                            if (decimalCount <= 1) {
                              onSelectTintsVolume(index, inputValue);
                            }
                          }}
                        />

                        </TableCell>
                      ) : (
                        null
                      )}

                      {props.isEditTableValue && (
                        <TableCell width="50%" align="center">
                          <Button
                            variant="contained"
                            color="error"
                            sx={{
                              marginLeft: 2,
                            }}
                            onClick={() => {
                              let data = [...props.defaultFormulationDetails];
                              data.splice(index, 1);
                              props.setDefaultFormulationDetails(data);
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      )}
                      {/* <TableCell align="center">{row.fat}</TableCell>
                            <TableCell align="center">{row.carbs}</TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {props.isEditTableValue && (
            <div className="w-full flex justify-end text-right p-6">
              <Button
                variant="contained"
                color="success"
                sx={{
                  marginLeft: 2,
                  display: "flex",
                  justifyContent: "end",
                }}
                onClick={addtint}
              >
                Add Tints
              </Button>
            </div>
          )}
        </>
      ):
      (
          <TableContainer component={Paper} style={{marginTop:4,borderRadius:'10px'}}>
            <Table  aria-label="caption table">
              <TableHead>
                <TableRow className="bg-[#cad1e1]">
                  <TableCell align="center">Tints</TableCell>
                  <TableCell align="center">Tint Volume {(volume)}</TableCell>
                 
                 </TableRow>
              </TableHead>
              <TableBody>
                {props.formulationDetails.length !== 0 &&
                  props.formulationDetails.map((item, index) => (
                    <TableRow key={index}>
                     

                        <TableCell width="50%" align="center">
                          {item.label}
                        </TableCell>
                      
                        <TableCell width="50%" align="center">
                          {item.value}
                        </TableCell>
  
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>     
            )
    
    }

    </div>
  );
}
