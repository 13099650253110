import React, { useState } from "react";
// import { startOfMonth } from 'date-fns'

const AppContext = React.createContext();

export const initialValueSet = {
  Login: {
    city: "",
    user_name: "",
    phone_number: "",
    dealer_region: "",
    dealer_name: "",
    dealer_city: "",
  },
  PaintOEM: {
    paint_manufacturer: "",
    paint_category: "",
  },
  PaintJobDetails: {
    phone_and_car_number: "",
    car_model: "",
    year: "",
    colour: "",
  },
  PaintJobPhoto: [],
  PaintJobTintDetails: {
    form_name_paint_job: "",
    volume_paint_job: "",
    paint_job_details: [],
  },
  PaintJobFinalImage: "",
  PaintFormulationDetails: {
    model_name: "",
    colour: "",
    year: "",
    paint_category: "",
    paint_manufacturer: "",
    colour: "",
    model_year_and_formulation_name: "",
  },
  PaintFormulationPhoto: [],
  PaintFormulationTintDetails: {
    volume_paint_job: "",
    paint_job_details: [],
  },
};

const AppContextProvider = (props) => {
  const initialValue = initialValueSet;

  const initialPaintValue = {
    PaintJobDetails: {
      phone_and_car_number: "",
      car_model: "",
      year: "",
      colour: "",
    },
    PaintJobPhoto: [],
    PaintJobTintDetails: {
      form_name_paint_job: "",
      volume_paint_job: "",
      paint_job_details: [],
    },
    PaintJobFinalImage: "",
    PaintFormulationDetails: {
      model_name: "",
      colour: "",
      year: "",
      paint_category: "",
      paint_manufacturer: "",
      colour: "",
      model_year_and_formulation_name: "",
    },
    PaintFormulationPhoto: [],
    PaintFormulationTintDetails: {
      volume_paint_job: "",
      paint_job_details: [],
    },
  };

  const [appLocalData, setAppLocalData] = useState(initialValue);

  return (
    <AppContext.Provider
      value={{
        appLocalData,
        setAppLocalData,
        initialValue,
        initialPaintValue,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export { AppContextProvider, AppContext };
