import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React from "react";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PhotoIcon from '@mui/icons-material/Photo';
import Box from '@mui/material/Box';


const TakePhotoDialogTwo = (props) => {
    const hiddenFileInput = React.useRef(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleChange = async (event) => {
        // props.setIsShowTakePhotoDialog(false)
        const fileUploaded = await event.target.files[0];
        const reader = await toBase64(fileUploaded);
        props.onSelectOption({
            type: 'Gallery',
            file: fileUploaded,
            reader: reader
        })
        props.setIsShowTakePhotoDialog(false)

    };

    return (
        <>
            <Dialog
                open={props.isShowTakePhotoDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: { borderRadius: 15 }
                }}
            >
                <DialogTitle id="alert-dialog-title" className="text-center font-bold rounded-lg">
                    <div className='px-6 py-4'>
                        <div className='text-xl font-bold'>
                            Take Photo From
                        </div>
                        <div className='mt-10 justify-evenly w-52 flex'>
                            <div>
                                <Box component="span" sx={{ p: '5px 10px', border: '1px dashed grey', cursor: 'pointer' }} onClick={() => {
                                    props.onSelectOption({ type: 'Camera' })
                                    props.setIsShowTakePhotoDialog(false)
                                }}>
                                    <CameraAltIcon className='text-[25px]' />
                                </Box>
                                <div className='text-[15px] mt-2'>
                                    Camera
                                </div>
                            </div>
                            <div>
                                <Box component="span" sx={{ p: '5px 10px', border: '1px dashed grey', cursor: 'pointer' }} onClick={() => {
                                    handleClick()
                                }}>
                                    <PhotoIcon className='text-[25px]' />
                                </Box>
                                <div className='text-[15px] mt-2'>
                                    Gallery
                                </div>
                            </div>
                            <input
                                type="file"
                                accept="image/png,image/jpg,image/jpeg"
                                ref={hiddenFileInput}
                                onChange={handleChange}
                                style={{ display: 'none' }}
                            />
                        </div>
                    </div>
                </DialogTitle>
            </Dialog >
        </>
    );
};

export default TakePhotoDialogTwo;
