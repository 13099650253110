import React from 'react';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import axios from 'axios';
import { ApiURL } from './AppHelpers';
import { useNavigate } from 'react-router-dom';
function Paint_Consumption_Report(props) {

  const navigate = useNavigate()
  let found_formulation_id = JSON.parse(
    localStorage.getItem("found_formulation_id")
  );
  const dealer_code = localStorage.getItem("dealer_name") ? JSON.parse(localStorage.getItem("dealer_name")).split(' - ')[1] : "";
  let dealer_password = localStorage.getItem("dealer_passsword");
  const vehicle_model = JSON.parse(localStorage.getItem("vehicle_model"));
  const consumption_data = JSON.parse(localStorage.getItem('selectedData'))
  const consumption_volume = localStorage.getItem('volume')
  let image = JSON.parse(localStorage.getItem("uploaded_image"));

  const convertedFormulationDetails = Object.values(props.formulationDetails).map(item => ({
    label: item.label,
    value: parseFloat(item.value)
  }));

   let sum = 0;
  let TotalVolume = localStorage.getItem('volume')

  for (let i = 0; i < convertedFormulationDetails.length; i++) {
    sum += convertedFormulationDetails[i].value;
  }

  const scalingFactor = TotalVolume / sum;
  const adjustedTintCodes = convertedFormulationDetails?.map(tint => ({
    label: tint.label,
    value: (tint.value * scalingFactor).toFixed(1)
  }));
 
  const tintCodes = {};
  for (let i = 0; i < convertedFormulationDetails.length; i++) {
    const tint = convertedFormulationDetails[i];
    const adjustedValue = (tint.value * scalingFactor).toFixed(1);
    tintCodes[tint.label] = adjustedValue;
  }

  for (let i = 0; i < consumption_data?.length; i++) {
    const item = consumption_data[i];
    if (item !== null && item !== undefined) {
      if (item.option === 0) {
        item.percentage = 100;
        item.option = "replace";
      } else {
        // No need to modify percentage if option is not 0
        item.option = "repair";
      }
    }
  }
  

  var edit =
  props.formulationDetails &&
  props.formulationDetails.reduce(
    (obj, item) =>
      item.label &&
      item.value &&
      Object.assign(obj, { [item.label]: item.value }),
    {}
  );
    //posting the data after the calculation
  const paintData = async (screenName) => {
    const headersList = {
      "Content-Type": "application/json",
    };

    let bodyContent = {
      "dealer_code": dealer_code,
      "dealer_password": dealer_password,
      "data": {
        "formulation_id": found_formulation_id,
        "used_predicted_data": props.usedPredictedData,
        "vehicle_name": vehicle_model,
        "distance": props.apprxDistance,
        "image_url": image,
        "consumption_data": consumption_data,
        "consumption_volume": consumption_volume,
        "consumption_tint_codes": tintCodes,
        "consumption_status": "true"
      }
    };

    if (!props.usedPredictedData) {
      bodyContent.data.edited_tint_codes = edit;  
    }
    const reqOptions = {
      url: `${ApiURL}webhook/dave/maruti_core/prediction-feedback`,
      method: "POST",
      headers: headersList,
      data: JSON.stringify(bodyContent),
    };

    try {
      let ans = await axios.request(reqOptions);

      if (ans) {
        window.localStorage.removeItem("panelName");
        window.localStorage.removeItem("found_formulation_id");
        window.localStorage.removeItem("repair");
        window.localStorage.removeItem("replace");
        window.localStorage.removeItem("panelOptions");
        window.localStorage.removeItem("consumption");
        window.localStorage.removeItem("volume");
        window.localStorage.removeItem("selectedData");

        if (screenName === "logout") {
          props?.context?.setAppLocalData({ ...props.context.initialValue });
          window.localStorage.clear();

          setTimeout(() => {
            navigate("/");
            localStorage.clear();
            localStorage.setItem("login", JSON.stringify(true));
          }, 2000);
        } else {
          props?.context?.setAppLocalData({
            ...props.context.appLocalData,
            ...props.context.initialPaintValue,
          });
        }
        props.setIsActiveWindow(1);
      }
    } catch (error) {
      console.log("error---", error);
    }
  };


  return (
    <div className=' mt-4 font-Oxanium '>
      <div className='flex justify-between'>

        <h1 className=' rounded-md  mt-4 py-2 bg-[#2D3494] w-full  flex-row text-white text-left font-semibold flex  sm:flex sm:flex-row  items-center justify-around  sm:mb-0'>Total Paint Consumption</h1>
      </div>
      <h1 className='text-[12px] md:text-base  mt-4 text-sm lg:text-base '>Total Volume for these panels is {TotalVolume} ML</h1>
      <TableContainer component={Paper} className='' sx={{ borderRadius:'10px' }}>
  <Table sx={{ border: 'none' }} aria-label="caption table">
    <TableHead>
    <TableRow className="bg-[#DCE0E9]">
  <TableCell align="center" style={{ fontWeight: 'bold', border: 'none' ,fontSize:'clamp(10px, 3vw, 16px)'}}>Tints</TableCell>
  <TableCell align="center" style={{ fontWeight: 'bold', border: 'none', padding: '2px',fontSize:'clamp(10px, 3vw, 16px)', whiteSpace: 'nowrap' }}>Total Paint Consumption(ml)</TableCell>
</TableRow>

    </TableHead>
    <TableBody style={{ margin: '-8px' }}>  
      {adjustedTintCodes.map((tint, index) => (
        <TableRow key={index}>
          <TableCell width="50%" align="center" className='bg-[#DCE0E9]' style={{ pointerEvents: 'none', border: 'none', padding: '4px' }}> 
            <TextField
              id={`tint-${index}`}
              variant="standard"
              value={tint.label}
              className="items-center"
              InputProps={{
                sx: {
                  "& .MuiInput-input": {
                    textAlign: "center",
                    fontFamily: 'font-Oxanium',
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                    fontWeight: "bold",
                  },
                },
                disableUnderline: true,
                style: {
                  padding: 0,
                },
              }}
              placeholder="Enter Tints"
            />
          </TableCell>
          <TableCell width="50%" align="center" className='bg-[#DCE0E9]' style={{ pointerEvents: 'none', border: 'none', padding: '4px' }}> 
            <TextField
              id={`tint-volume-${index}`}
              variant="standard"
              value={tint.value}
              className="items-center"
              InputProps={{
                sx: {
                  "& .MuiInput-input": {
                    textAlign: "center",
                    fontFamily: 'font-Oxanium',
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                    color: "#2D3494",
                    fontWeight: "bold",
                    borderRadius: "6px",
                  },
                },
                disableUnderline: true,
                style: {
                  padding: 0,
                },
              }}
              placeholder="Tint Volume"
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>


   <div className='flex justify-between'>
    <button></button>
    <button className='bg-[#002783] text-white px-4 mt-2 py-2 rounded-md text-xs sm:text-sm lg:text-sm md:text-sm lg:px-6'
     onClick={() => {
      paintData()
      props.onConfirmPress()
    }}
    >SUBMIT</button>
   </div>
    </div>

  )
}

export default Paint_Consumption_Report;
