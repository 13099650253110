import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";

export const AppLoader = () => {
  return (
    <div
      className="absolute top-0 bottom-0 left-0 right-0 z-10"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.35)" }}
    >
      <Box
        sx={{ display: "flex" }}
        className="absolute flex top-0 bottom-0 left-0 right-0 justify-center items-center flex-col"
      >
        <CircularProgress />
      </Box>
    </div>
  );
};

export default AppLoader;
