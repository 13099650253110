import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import GetVehicleModel from "./Screens/GetVehicleModel";
import PaintFormulationReport from "./Screens/PaintFormulationReport";
import PaintSelection from "./Screens/PaintSelection";
import ScanImage from "./Screens/ScanImage";
import ScanTheCar from "./Screens/ScanTheCar";
import TakePhoto from "./Screens/TakePhoto";
import ApprovalDashboard from "./Screens/ApprovalDashboard";
import React from "react";
import LogIn from "./Screens/LogIn";
import NewFormulation from './Screens/NewPaintFormulation'
import SelectFormulation from './Screens/selectFormulation'
import PaintFormulation from "./Screens/PaintFormulation";
// import Dealerapproval from "./Screens/Dealerapproval";
  export default function App() {
  return (
    <Router>
      <div className="bg-[#f3f3f3] w-full h-[100vh] uppercase">
        <Routes>
          <Route path="/" element={<TakePhoto />} />
          <Route path="/log-in" element={<LogIn />} />
          <Route path="scan-image" element={<ScanImage />} />
          <Route path="vehicle-model" element={<GetVehicleModel />} />
          <Route
            path="paint-formulation-report"
            element={<PaintFormulationReport />}
          />
          <Route path="paint-selection" element={<PaintSelection />} />
          <Route path="scan-the-car" element={<ScanTheCar />} />
          {/* <Route path="Add-new-dealer" element={<AddNewVehicle />} /> */}
          {/* <Route path="Approval-dashboard" element={<ApprovalDashboard />} /> */}
          {/* <Route path="New-paint-formulation" element={<NewFormulation />} /> */}
          {/* <Route path="select_formualation" element={<SelectFormulation />} /> */}
          {/* <Route path="scan-new-car" element={<PaintFormulation />} /> */}
          {/* <Route path="dealer-approval-status" element={<Dealerapproval />} /> */}
         </Routes>
      </div> 
    </Router>
  );
}


