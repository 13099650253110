import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React from "react";

const IncorrectValueDialog = (props) => {
  return (
    <>
      <Dialog
        open={props.isShowEditableValue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 15 },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="text-center font-bold rounded-lg"
        >
          <FormControl className="w-full">
            <FormLabel
              className="text-left"
              id="demo-radio-buttons-group-label"
            >
              Incorrect Field
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={props.isEditableValue}
              name="radio-buttons-group"
              row
              onChange={(e) => {
                props.setIsEditableValue(e.target.value);
              }}
            >
              <FormControlLabel value="Tint" control={<Radio />} label="TINT" />
              <FormControlLabel
                value="Volume"
                control={<Radio />}
                label="VOLUME"
              />
            </RadioGroup>
          </FormControl>
          <div className="mt-4 flex">
            <div>
              <Button
                variant="contained"
                startIcon={
                  <div className="flex items-center absolute top-0 bottom-0 left-0 px-1">
                    <ChevronLeftIcon />
                  </div>
                }
                onClick={() => {
                  props.setIsShowEditableValue(false);
                  props.setIsEditTableValue(false);
                }}
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #3D5A9F",
                  color: "#3D5A9F",
                  paddingLeft: 50,
                  paddingRight: 15,
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="ml-10">
              <Button
                variant="contained"
                endIcon={
                  <div className="bg-[#1e3e88] flex items-center absolute top-0 bottom-0 right-0 px-1">
                    <ChevronRightIcon />
                  </div>
                }
                onClick={() => {
                  props.setIsShowEditableValue(false);
                  props.setIsEditTableValue(true);
                }}
                style={{
                  backgroundColor: "#3c5a9f",
                  paddingRight: 50,
                  paddingLeft: 15,
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </DialogTitle>
      </Dialog>
    </>
  );
};

export default IncorrectValueDialog;
