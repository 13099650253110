import CapturePicture from "./CapturePicture";
import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ApiURL, AppHeader } from "../../Components/AppHelpers";
import ScanCarAndInfo from "./infoModals";
import "./scanTheCar.css";
import useWindowDimensions from "../../hooks/windowDimention";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Button from "@mui/material/Button";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Loginpop from "../logoutpopup";
function ScanTheCar() {
  const navigate = useNavigate();  
  const [carImage, setCarImage] = useState([]);
  const [carImageFile, setCarImageFile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isVideoPlay, setIsVideoPlay] = useState(false);
  const [dataLoaded, setDataLoaded] = useState([]);
  const [selectedLang, setSelectedLang] = useState();
  const [langSubmit, setLangSubmit] = useState(false);
  const [canScanTheCar, setCanScanTheCar] = useState(false);
  const [langError, setLangError] = useState(false);
  const {mobile} = useWindowDimensions();
  const videoRef = useRef(null);
  const handleVideoEnded = () => {
    setIsVideoPlay(false);
   // console.log();
   navigate("/paint-formulation-report");
 };
 useEffect(() => {
   if (videoRef.current) {
       const handlePause = () => {
           if (videoRef.current && videoRef.current.paused) {
            navigate("/paint-formulation-report");
             
           }
       };
        videoRef.current.addEventListener('pause', handlePause);
        return () => {
           if (videoRef.current) {
               videoRef.current.removeEventListener('pause', handlePause);
           }
       };
   }
}, [videoRef, isVideoPlay]); 

   
  

  useEffect(() => {
    let logIn = JSON.parse(localStorage.getItem("login"));
    if (logIn != undefined) {
      if (!logIn) {
        navigate("/log-in");
      }
    } else {
      navigate("/log-in");
    }
  }, []);

  useEffect(() => {
    if (langError) {
      setTimeout(() => {
        setLangError(false);
      }, 5000);
    }
  }, [langError]);
  
  return (
    <>
      {isVideoPlay ? (
        <div className="w-full h-full flex items-center justify-center">
          <video ref={videoRef} width="750" height="500" autoPlay onEnded={handleVideoEnded}>
            <source src={require("../../assets/movideo.mp4")} type="video/mp4" />
          </video>
        </div>
      ) : (
        <>
          <img
            src={require("../../assets/bg-plain.png")}
            className="h-[100vh] w-[100vw]"
          />
          <div className="absolute flex top-0 bottom-0 left-0 right-0 justify-center items-center flex-col">
            <div className="w-[80%] h-[85%] overflow-hidden justify-center items-center text-center">
              {canScanTheCar ? (
                <>
                  <div className="text-center uppercase font-bold mb-2">
                    Scan The Car
                  </div>
                  <div className="w-full h-full overflow-hidden">
                    <CapturePicture
                      setCanScanTheCar={setCanScanTheCar}
                      carImage={carImage}
                      setCarImage={setCarImage}
                      setCarImageFile={setCarImageFile}
                      setIsVideoPlay={setIsVideoPlay}
                    />
                  </div>
                </>
              ) : (
                <>
                  {langSubmit ? (
                    <ScanCarAndInfo
                      carImage={carImage}
                      setCarImage={setCarImage}
                      setCarImageFile={setCarImageFile}
                      setIsVideoPlay={setIsVideoPlay}
                      selectedLang={selectedLang}
                      setLangSubmit={setLangSubmit}
                      setCanScanTheCar={setCanScanTheCar}
                    />
                  ) : (
                    <div
                      className= {["stcContainerLang", mobile && "mob"].join(
                        " "
                      )}
                    >
                      <p className="">SELECT YOUR PREFERRED LANGUAGE</p>
                      <div
                        className={["langSelection", mobile && "mob"].join(" ")}
                      >
                        {[
                          "ENGLISH",
                          "HINDI",
                          "BENGALI",
                          "GUJARATI",
                          "TELUGU",
                          "TAMIL",
                          //   "KANNADA",
                        ].map((i, index) => {
                          return (
                            <button
                              key={i}
                              style={{
                                fontFamily:"Oxanium-semibold",
                                backgroundColor:
                                  selectedLang == index ? "#002883" : "white",
                                color:
                                  selectedLang == index ? "white" : "black",
                              }}
                              onClick={() => setSelectedLang(index)}
                            >
                              {i}
                            </button>
                          );
                        })}
                      </div>
                      {langError && (
                        <p
                          style={{
                            fontWeight: 400,
                            fontSize: "1.8vh",
                            color: "red",
                            marginTop: "2vh",
                            marginBottom: "2.5vh",
                          }}
                        >
                          Please select a language to continue.
                        </p>
                      )}
                         <div className="flex justify-between mt-2 gap-4">
                            <button
                              className="rounded-md border border-[#3c5a9f] text-[#] px-4 py-1  text-sm md:text-base lg:px-14 lg:py-1 lg:text-base"
                              onClick={() => {
                                navigate("/paint-selection");
                              }}
                            >
                              <ChevronLeftIcon
                               style={{
                                marginRight: "0px",
                                color:"#3c5a9f"
                              }} />  <span style={{ marginRight: "8px",color:"#3c5a9f",fontFamily:"Oxanium"}}>  BACK</span>

                            </button>
                            <button
                              className="bg-[#3c5a9f] rounded-md text-white px-4  text-center text-sm md:text-base lg:px-16  lg:text-base"
                              onClick={() => {
                                if (selectedLang === undefined) {
                                  setLangError(true);
                                } else {
                                  setLangSubmit(true);
                                }
                              }}
                            >
                              <span style={{ marginLeft: "6px" ,fontFamily:"Oxanium"}}>NEXT</span><ChevronRightIcon tyle={{
                                marginRight: "2px",
                              }}/>
                            </button>
                          </div>
                          <div className="mt-8">
                          <Loginpop/>
                          </div>
                      </div>
                   )}
                </>
              )} 
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ScanTheCar;
