import AddIcon from "@mui/icons-material/Add";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import RemoveIcon from "@mui/icons-material/Remove";
import MuiAlert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import axios from "axios";
import produce from "immer";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiURL, AppHeader } from "../../../Components/AppHelpers";
import ExitDialog from "../../../Components/ExitDialog";
import GoBackDialog from "../../../Components/GoBackDialog";
import WithAppContext from "../../../Helpers/context/appContext/appContext";
import { volumeData } from "../data";
import { Dialog, DialogTitle } from "@mui/material";
import { initialValueSet } from "../../../Helpers/context/appContext/app.contexts";

export const logOutClearData = (bool = true) => {
  localStorage.removeItem("uploaded_image");
  bool && localStorage.removeItem("login");
  // localStorage.removeItem("dealer_name");
  // localStorage.removeItem("city_name");
  localStorage.removeItem("pincode");
  localStorage.removeItem("dealer_region");
  localStorage.removeItem("formulationdetails");
  localStorage.removeItem("phone_number");
  // localStorage.removeItem("paintManufacturer");
  localStorage.removeItem("formulation_image1");
  localStorage.removeItem("paintCategory");
  localStorage.removeItem("dealer_city");
  localStorage.removeItem("username");
  localStorage.removeItem("tint_codes");
  localStorage.removeItem("formulation_name");
  localStorage.removeItem("colour");
  localStorage.removeItem("color_name");
  localStorage.removeItem("vehicle_model");
};

function AddFormulationDetails(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isShowCloseModel, setIsShowCloseModel] = useState(false);
  const [isSaveDetails, setIsSaveDetails] = useState(false);
  const [isShowBackModel, setIsShowBackModel] = useState(false);
  const [formulationDetailsData, setFormulationDetailsData] = useState([]);
  const [formulationDetails, setFormulationDetails] = useState([]);
  const [tinsData, setTinsData] = useState([]);
  const [volume, setVolume] = useState("");
  const [isCheckValidation, setIsCheckValidation] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [dataSavedModal, setDataSavedModal] = useState(false);

  const [volumeInfoModal, setVolumeInfoModal] = useState(true);

  const onIncreaseCount = (index) => {
    let value = (Number(formulationDetails[index].value) + 0.5).toFixed(2);
    formulationDetails[index] = { ...formulationDetails[index], value: value };
    onSetFormulationDetailsData([...formulationDetails]);
  };

  const onDecreaseCount = (index) => {
    let value = (Number(formulationDetails[index].value) - 0.5).toFixed(2);
    if (value > 0) {
      formulationDetails[index] = {
        ...formulationDetails[index],
        value: value,
      };
      onSetFormulationDetailsData([...formulationDetails]);
    }
  };

  const onSelectTintsValue = (index, value) => {
    formulationDetails[index] = { ...formulationDetails[index], label: value };
    onSetFormulationDetailsData([...formulationDetails]);
  };

  const onRemoveTints = (index) => {
    let data = [...formulationDetails];
    data.splice(index, 1);
    onSetFormulationDetailsData(data);
  };

  const onAddTints = () => {
    let data = [...formulationDetails];
    data.push({
      label: "AM2",
      value: 4.3,
    });
    onSetFormulationDetailsData(data);
  };

  useEffect(() => {
    if (props.context.appLocalData) {
      if (props.context.appLocalData.PaintFormulationTintDetails) {
        setVolume(
          props.context.appLocalData.PaintFormulationTintDetails
            .volume_paint_job
        );
        onSetFormulationDetailsData(
          props.context.appLocalData.PaintFormulationTintDetails
            .paint_job_details
        );
      }
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const paintManufacturer = JSON.parse(
      localStorage.getItem("paintManufacturer")
    );
    const paintCategory = JSON.parse(localStorage.getItem("paintCategory"));
    const color_name = JSON.parse(localStorage.getItem("color_name"));
    // const formulation_name=JSON.parse(localStorage.getItem('formulation_name'))
    if (
      paintManufacturer != null &&
      paintCategory != null &&
      color_name != null
    ) {
      axios
        .get(
          `${ApiURL}objects/paint_formulation?manufacturer_name=${paintManufacturer}&colour_name=${color_name}&paint_category_name=${paintCategory}`,
          { headers: AppHeader }
        )
        .then((res) => {
          if (res) {
            var form_details = [];
            const d = res.data.data[0];
            for (let key in d.tint_codes) {
              form_details.push({
                label: key,
                value: d.tint_codes[key],
              });
            }
            onSetFormulationDetailsData(form_details);
            // setTinsData(form_details)
          }
        });
    }
  }, []);

  useEffect(() => {
    const paintManufacturer = JSON.parse(
      localStorage.getItem("paintManufacturer")
    );
    const paintCategory = JSON.parse(localStorage.getItem("paintCategory"));
    const color_name = JSON.parse(localStorage.getItem("color_name"));
    // const formulation_name=JSON.parse(localStorage.getItem('formulation_name'))
    if (
      paintManufacturer != null &&
      paintCategory != null &&
      color_name != null
    ) {
      axios
        .get(
          `${ApiURL}pivot/paint_formulation/tint_codes?manufacturer_name=${paintManufacturer}&colour_name=${color_name}&paint_category_name=${paintCategory}&_fresh=True`,
          { headers: AppHeader }
        )

        .then((res) => {
          if (res) {
            var form_details1 = [];
            const d = res.data.data;
            for (let key in d) {
              form_details1.push({
                label: key,
                // value: d.tint_codes[key]
              });
            }
            setTinsData(form_details1);
          }
        });
    }
  }, []);

  const storeLocalData = (screenName) => {
    let username = JSON.parse(localStorage.getItem("username"));
    let phonenumber = JSON.parse(localStorage.getItem("phone_number"));
    let city_name = JSON.parse(localStorage.getItem("dealer_city"));
    let dealer_pincode = JSON.parse(localStorage.getItem("pincode"));
    let dealer_name = JSON.parse(localStorage.getItem("dealer_name"));
    const paintManufacturer = JSON.parse(
      localStorage.getItem("paintManufacturer")
    );
    const paintCategory = JSON.parse(localStorage.getItem("paintCategory"));
    let color_name = JSON.parse(localStorage.getItem("color_name"));
    let formulation_name = JSON.parse(localStorage.getItem("formulation_name"));
    const volume = JSON.parse(localStorage.getItem("volume_paint_formulation"));
    console.log("form-name-->", formulation_name);
    console.log("color_name-->", color_name);
    console.log("dealer_pincode-->", dealer_pincode);
    if (city_name != null) {
      city_name = city_name;
    } else {
      city_name = "city_empty";
    }

    if (dealer_pincode != null) {
      dealer_pincode = dealer_pincode;
    } else {
      dealer_pincode = "";
    }
    if (color_name != null) {
      color_name = color_name;
    } else {
      color_name = "Silky Silver";
    }

    if (dealer_name != null) {
      dealer_name = dealer_name;
    } else {
      dealer_name = "delear_name_empty";
    }
    if (formulation_name != null) {
      console.log("form-nam11e-->", formulation_name);

      formulation_name = formulation_name;
    } else {
      formulation_name = paintCategory + " " + "custom";
    }

    // const formulation_image1 = JSON.parse(localStorage.getItem('formulation_image1'))
    const formulationdetails = JSON.parse(
      localStorage.getItem("formulationdetails")
    );
    var object =
      formulationdetails &&
      formulationdetails.reduce(
        (obj, item) => Object.assign(obj, { [item.label]: item.value }),
        {}
      );
    axios({
      method: "post", //put
      url: `${ApiURL}object/formulations_approval`,
      headers: AppHeader,
      data: {
        dealer_city: city_name,
        dealer_name: dealer_name,
        dealer_pincode: dealer_pincode,
        formulation_name: formulation_name,
        paint_category_name: paintCategory,
        // colour_id:"Z2S",
        username: username,
        phone_number: phonenumber,
        volume: volume,
        colour_name: color_name,
        tint_codes: object,
        manufacturer_name: paintManufacturer,
        image1: props.carImageFile.length !== 0 && props?.carImageFile[0],
        image2: props.carImageFile.length !== 0 && props?.carImageFile[1],
        image3: props.carImageFile.length !== 0 && props?.carImageFile[2],
      },
    })
      .then((res) => {
        if (res) {
          window.localStorage.removeItem("volume_paint_formulation");
          window.localStorage.removeItem("formulation_name");
          window.localStorage.removeItem("formulation_image1");
          window.localStorage.removeItem("formulationdetails");
           if (screenName === "logout") {
            props.context.setAppLocalData({ ...props.context.initialValue });
            window.localStorage.clear();
            navigate("/");
          } else {
            props.context.setAppLocalData({
              ...props.context.appLocalData,
              ...props.context.initialPaintValue,
            });
            // navigate("/paint-category");
          }
          // props.setIsActiveWindow(1);
        }
      })
      .catch((errors) => {
        console.log("error---", errors);
        setAlertMessage(errors.response.data.error);
        setOpenAlert(true);
      });
  };

  const onSaveData = (screenName) => {
    // alert('send');
    console.log(formulationDetails, volume, "/???????");
    setIsCheckValidation(true);
    localStorage.setItem(
      "formulationdetails",
      JSON.stringify(formulationDetails)
    );
    if (volume) {
      storeLocalData(screenName);
      if (screenName == "save") {
        setDataSavedModal(true);
        setTimeout(() => {
          navigate("/");
          setDataSavedModal(false);
        }, 3000);
      }
    }
    // setIsSaveDetails(true)
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const onSetFormulationDetailsData = (data) => {
    if (data) {
      const nextState = produce(props.context.appLocalData, (draft) => {
        draft.PaintFormulationTintDetails.paint_job_details = [...data];
        return draft;
      });
      setFormulationDetails([...data]);
      localStorage.setItem("formulationdetails", JSON.stringify([...data]));
      props.context.setAppLocalData(nextState);
    }
  };

  if (isLoading) {
    return (
      <div className="h-full w-full justify-center items-center flex">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Dialog
        open={dataSavedModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="text-green-900">
          {"Data Successfully Sent for Review"}
        </DialogTitle>
      </Dialog>
      <Dialog
        open={volumeInfoModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="text-green-900">
          {"Please make sure to enter total volume in 'metrics.' Example: ML/L"}
          <br />
          {
            "And the total volume must not exceed the sum of the tint volumes. If you are not sure please ask the painter."
          }
          <br />
          {
            "By default the tint volumes are shown in for total volume of 100ml. If you are changing the total volume please change the tint volumes too. "
          }
          <br />
          <br />

          <Button
            className="w-full mt-2"
            sx={{
              color: "#002783",
              border: "1px solid #002783",
            }}
            onClick={() => {
              setVolumeInfoModal(false);
            }}
          >
            CLOSE
          </Button>
        </DialogTitle>
      </Dialog>
      <div className="justify-end flex w-full m-1 mr-[12px]">
        {/* <div
          className="bg-[red] rounded-md"
          onClick={() => {
            setIsShowCloseModel(true);
          }}
        >
          <IconButton aria-label="delete">
            <CloseIcon sx={{ color: "#fff" }} />
          </IconButton>
        </div> */}
      </div>
      <div className="text-center uppercase font-bold mb-option2">
        Add Formulation Details
      </div>
      <div className="w-[90%] uppercase mb-1 mt-2 text-[#9d9fa2]">
        Volume<span>*</span>
      </div>
      {/* <Autocomplete
        required
        options={volumeData.map((option) => option.label)}
        className="w-[90%] bg-[#9d9fa2]"
        sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" } }}
        defaultValue={volume}
        onChange={(event, value) => {
          setVolume(value);
          setIsCheckValidation(false);
          localStorage.setItem(
            "volume_paint_formulation",
            JSON.stringify(value)
          );
          const nextState = produce(props.context.appLocalData, (draft) => {
            draft.PaintFormulationTintDetails.volume_paint_job = value;
            return draft;
          });
          props.context.setAppLocalData(nextState);
        }}
        renderInput={(params) => (
          <TextField placeholder="Enter Volume" {...params} />
        )}
      /> */}
      <TextField
        required
        className="w-[90%] bg-[#9d9fa2]"
        sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" } }}
        value={volume}
        placeholder="Enter Volume"
        onChange={(event) => {
          setVolume(event.target.value);
          setIsCheckValidation(false);
          localStorage.setItem(
            "volume_paint_formulation",
            JSON.stringify(event.target.value)
          );
          const nextState = produce(props.context.appLocalData, (draft) => {
            draft.PaintFormulationTintDetails.volume_paint_job =
              event.target.value;
            return draft;
          });
          props.context.setAppLocalData(nextState);
        }}
      />
      {isCheckValidation && !volume && (
        <div className="text-red-500 w-[90%]">Volume is Required</div>
      )}
      <div className="w-[95%] bg-[#e5e9f2] h-full rounded-2xl flex flex-col justify-evenly mt-5">
        <div className="w-[98%] bg-[#ced5e6] h-[98%] rounded-2xl flex justify-evenly relative self-center">
          <div className="w-2/5 h-full flex flex-col text-center pt-3 font-bold">
            Tints
          </div>
          <div className="w-3/5 h-full flex flex-col text-center pt-3 font-bold">
            Tint Volume
          </div>
          <div className="absolute w-full h-[93.7%] overflow-auto mt-11 mb-1">
            {formulationDetails.map((item, index) => {
              return (
                <div key={index} className="flex justify-evenly">
                  <div className="w-2/5 h-full flex flex-col text-center font-bold">
                    <div className="flex my-1 ml-2">
                      <div className="bg-[red] rounded-md">
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            onRemoveTints(index);
                          }}
                        >
                          <RemoveIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </div>
                      <div className="bg-white ml-2 w-[100%] flex justify-center items-center">
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          value={item.label}
                          InputProps={{
                            sx: {
                              "& .MuiInput-input": {
                                padding: 0,
                                textAlign: "center",
                              },
                            },
                            disableUnderline: true,
                            style: {
                              padding: 0,
                            },
                          }}
                          placeholder="Enter Tint"
                          onChange={(event) => {
                            onSelectTintsValue(index, event.target.value);
                          }}
                        />
                        {/* <TextField
                                                    select
                                                    value={item.label}
                                                    onChange={(event) => {

                                                        onSelectTintsValue(index, event.target.value)
                                                    }}
                                                    placeholder="Enter Tint"
                                                    className='w-full'
                                                    sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" }, }}
                                                    SelectProps={{
                                                        sx: { "& .MuiSelect-select": { padding: 0 }, },
                                                        style: {
                                                            padding: 0
                                                        },
                                                        IconComponent: (props) => {
                                                            return (
                                                                <div className='bg-[#1e3e88]'>
                                                                    <ExpandMoreOutlinedIcon {...props} style={{ color: '#000' }} />
                                                                </div>
                                                            )
                                                        }
                                                    }}
                                                >
                                                    {tinsData.map((option, i) => (
                                                        <MenuItem key={i} value={option.label}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField> */}
                      </div>
                    </div>
                  </div>
                  <div className=" w-3/5 h-full flex flex-col text-center font-bold">
                    <div className="bg-white mx-2 my-1 py-2 flex justify-between px-3">
                      <RemoveIcon
                        className="text-[grey] cursor-pointer"
                        onClick={() => {
                          onDecreaseCount(index);
                        }}
                      />
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        type="number"
                        value={item.value}
                        InputProps={{
                          sx: {
                            "& .MuiInput-input": {
                              padding: 0,
                              textAlign: "center",
                            },
                          },
                          disableUnderline: true,
                          style: {
                            padding: 0,
                          },
                        }}
                        placeholder="Enter Tint Value"
                        onChange={(event) => {
                          if (event.target.value >= 0) {
                            let FData = [...formulationDetails];
                            FData[index] = {
                              ...FData[index],
                              value: event.target.value,
                            };
                            onSetFormulationDetailsData([...FData]);
                          }
                        }}
                      />
                      {/* {item.value} */}
                      <AddIcon
                        className="text-[grey] cursor-pointer"
                        onClick={() => {
                          onIncreaseCount(index);
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="flex justify-evenly">
              <div className="w-2/5 flex flex-col text-center font-bold ml-2">
                <div className="flex my-1">
                  <Button
                    onClick={() => {
                      onAddTints();
                    }}
                    variant="outlined"
                    endIcon={<AddIcon />}
                    sx={{
                      color: "#002783",
                      border: "1px solid #002783",
                      backgroundColor: "#fff",
                      width: "100%",
                    }}
                  >
                    Add Tint
                  </Button>
                </div>
              </div>
              <div className="w-3/5 h-full flex flex-col text-center font-bold" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between w-[80%] my-6 sm:flex sm:flex-row flex-col">
        <div className="w-full sm:w-1/4 mb-2">
          <Button
            className="w-full mt-2"
            onClick={() => {
              setIsShowBackModel(true);
              // props.setIsActiveWindow(props.isActiveWindow - 1)
            }}
            variant="outlined"
            startIcon={<ChevronLeftIcon />}
            sx={{
              color: "#002783",
              border: "1px solid #002783",
            }}
          >
            Back
          </Button>
        </div>
        <div className="w-full sm:w-1/4 mb-2">
          <Button
            className="w-full mt-2"
            onClick={() => {
              onSaveData("save");
              logOutClearData(false);
              setTimeout(() => {
                props.context.setAppLocalData(initialValueSet);
              }, 1000);
              // setIsShowCloseModel(true)
            }}
            disabled={isCheckValidation && !volume}
            variant="outlined"
            endIcon={<ChevronRightIcon />}
            sx={{
              color: "#002783",
              border: "1px solid #002783",
            }}
          >
            Send
          </Button>
        </div>
        <div className="w-full sm:w-1/4 mb-2">
          <Button
            className="w-full mt-2"
            onClick={() => {
              // onSaveData("logout");
              logOutClearData();

              setTimeout(() => {
                navigate("/");
                props.context.setAppLocalData(initialValueSet);
                setDataSavedModal(false);
              }, 1000);
            }}
            variant="outlined"
            endIcon={<LogoutIcon />}
            sx={{
              color: "#002783",
              border: "1px solid #002783",
            }}
          >
            Logout
          </Button>
        </div>
      </div>
      <ExitDialog
        isSaveDetails={isSaveDetails}
        isShowCloseModel={isShowCloseModel}
        setIsShowCloseModel={() => onSaveData()}
        setIsShowCloseModelOnly={() => setIsShowCloseModel(false)}
        isActiveWindow={props.isActiveWindow}
        setIsActiveWindow={props.setIsActiveWindow}
      />
      <GoBackDialog
        isShowBackModel={isShowBackModel}
        setIsShowBackModel={setIsShowBackModel}
        isActiveWindow={props.isActiveWindow}
        setIsActiveWindow={props.setIsActiveWindow}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "50%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default WithAppContext(AddFormulationDetails);
