 import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
 import Grid from "@mui/material/Grid";
 import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, json } from "react-router-dom";
import AppLoader from "../../Components/AppLoader";
import IncorrectValueDialog from "../../Components/IncorrectValueDialog";
import PaintFormulation from "../../Components/PaintFormulationTable";
import axios from "axios";
import { Dialog, DialogTitle } from "@mui/material";
import { ApiURL } from "../../Components/AppHelpers";
import { AppHeader } from "../../Components/AppHelpers";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Logout from "../logoutpopup";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Paint_consumption from "../../Components/Paint_consumption";
import Alert from '@mui/material/Alert';

 
const PaintFormulationReport = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [predictedColor, setPredictedColor] = useState(null);
  const [matchedColor, setMatchedColor] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [volume, setVolume] = useState("1 litr")
  // const [paintFormulationId, setPaintFormulationId] = useState(null)
  const [vehicleModel, setVehicleModel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tintloading, setTintLoading] = useState(true);
  const [colour, setColour] = useState(null);
  const [isShowEditableValue, setIsShowEditableValue] = useState(false);
  const [isEditableValue, setIsEditableValue] = useState("Tint");
  const [isEditTableValue, setIsEditTableValue] = useState(false);
  const [isHideDesiredColourFormulation, setIsHideDesiredColourFormulation] = useState(null);
  const [isOpenTost, setIsOpenTost] = useState(false);
  const [dataSavedModal, setDataSavedModal] = useState(false);
  const [changeMessage, setChangeMessage] = useState(false);
  const [isColourNotMatch, setIsColourNotMatch] = useState(false);
  const [isShowOpenTintVolume, setIsShowOpenTintVolume] = useState(false);
  const [usedPredictedData, setUsedPredictedData] = useState(true);
  const [predictionStatus, setPredictionStatus] = useState(true);
  const [formulationDetails, setFormulationDetails] = useState([]);
  const [defaultFormulationDetails, setDefaultFormulationDetails] = useState([]);
  const [isTableEmpty, setIsTableEmpty] = useState(false);
  const hiddenFileInput = useRef(null);
  const [showMSG, setShowMSG] = useState(false);
  const [pCunsumptionPop, setPcumsumptionPop] = useState(false)
const[openAlert,setOpenAlert]=useState(false)
  const [showConsumptionComp, setShowConsumptionComp] = useState(() => {
    const storedValue = localStorage.getItem("consumption");
    return storedValue ? JSON.parse(storedValue) : false;
  });  
  const[panelLoading,setPanelLoading]=useState(false)
   const bottomRef = useRef(null);
  const [distance, setDistance] = useState("")

  const toggleLoading = (status) => {
     setPanelLoading(status);
  };

  useEffect(() => {
    // Display the loader initially
    const loaderTimeout = setTimeout(() => {
      setTintLoading(false);
    }, 10000);

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(loaderTimeout);
  }, []);
  useEffect(() => {
    let logIn = JSON.parse(localStorage.getItem("login"));
    if (logIn != undefined) {
      if (!logIn) {
        navigate("/log-in");
      }
    } else {
      navigate("/log-in");
    }
  }, []);

  useEffect(() => {
    let loadingTimeout;
    let slowInternetTimeout;

    // Function to show the slow internet popup
    const showSlowInternetPopup = () => {
      setIsShowOpenTintVolume(true);
      setIsLoading(false);
    };

    // Set loading timeout
    loadingTimeout = setTimeout(() => {
      if (isLoading) {
        setShowMSG(true);

        // Set slow internet timeout
        slowInternetTimeout = setTimeout(showSlowInternetPopup, 50000);
      }
    }, 100000);

    return () => {
      // Clear the timeouts when the component unmounts or when isLoading changes
      clearTimeout(loadingTimeout);
      clearTimeout(slowInternetTimeout);
    };
  }, [isLoading]);

  useEffect(() => {
    if (showMSG) {
    }
  }, [showMSG]);
  // const [dataLoaded, setDataLoaded] = useState([]);

  // useEffect(() => {
  //     axios.get(`${ApiURL}/objects/paint_formulation?original_paint_formulation=false&page_size=1000`, {headers : AppHeader })
  //         .then(res => {
  //             if (res) {
  //                 setDataLoaded(res.data.data)
  //                 setIsLoading(false)
  //             }
  //         }).catch(err => {
  //             setIsLoading(false)
  //         })
  // },[])
  // localStorage.setItem("tint_codes",JSON.stringify(dataLoaded.map((option) => option.tint_codes)))
 

  useEffect(() => {
    if (location?.state?.type !== "VehicleModel") {
      const uploaded_image = JSON.parse(localStorage.getItem("uploaded_image"));
      const dealer_name = JSON.parse(localStorage.getItem("dealer_name"));
      const vehicle_model = JSON.parse(localStorage.getItem("vehicle_model"));
      const colour = JSON.parse(localStorage.getItem("colour"));
      if (uploaded_image) {
        setSelectedImage(uploaded_image);
      }
      if (dealer_name) {
        setCustomerName(dealer_name);
      }
      if (vehicle_model) {
        setVehicleModel(vehicle_model);
      }
      if (colour) {
        setColour(colour);
      }
    }
  }, []);

  useEffect(() => {
    if (location.state && location.state.type === "VehicleModel") {
      const vehicle_name = location.state.vehicle_model;
      if (vehicle_name != null) {
        axios
          .get(
            `${ApiURL}objects/predict_paint_formulation?vehicle_name=${vehicle_name}`,
            { headers: AppHeader }
          )
          .then((res) => {
            if (res) {
              var form_details = [];
              const d = res.data.data[0];
              if (d.dealer_name) {
                setCustomerName(d.dealer_name);
              }
              if (d.vehicle_name) {
                setVehicleModel(d.vehicle_name);
              }
              if (d.volume) {
                setVolume(d.volume)
              }
              if (d.image1) {

                if (d.colour) {
                  setColour(d.colour_name)
                }
                setSelectedImage(d.image1);
                var rgb = { r: 192, g: 192, b: 192 };
                setMatchedColor(`rgb(${rgb.r},${rgb.g},${rgb.b})`);

              }
              if (d.paint_formulation_id) {
                // setPaintFormulationId(d.paint_formulation_id)
                localStorage.setItem(
                  "found_formulation_id",
                  JSON.stringify(d.paint_formulation_id)
                );
              }
              if (d.volume == "1 Litter" || d.volume == "1 litre") {
                for (let key in d.edited_tint_codes) {
                  form_details.push({
                    label: key,
                    value: (d.edited_tint_codes[key] / 10).toFixed(1),
                  });
                }
                setFormulationDetails(form_details);
                setDefaultFormulationDetails(form_details);

                setIsHideDesiredColourFormulation(null);
              } else if (
                d.volume == null ||
                d.volume == "100 ML" ||
                d.volume == "100 g"
              ) {
                for (let key in d.edited_tint_codes) {
                  form_details.push({
                    label: key,
                    value: d.edited_tint_codes[key],
                  });
                }

                setFormulationDetails(form_details);
                setDefaultFormulationDetails(form_details);
                setIsHideDesiredColourFormulation(false);
              }
            }
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }
    }
  }, []);

  useEffect(() => {
    if (
      !isOpenTost &&
      isHideDesiredColourFormulation === false &&
      location?.state?.type !== "VehicleModel"
    ) {
      setTimeout(() => {
        setIsOpenTost(true);
      }, 10000);
    }
  }, [isOpenTost]);

  useEffect(() => {
    if (
      isOpenTost &&
      isHideDesiredColourFormulation === false &&
      location?.state?.type !== "VehicleModel"
    ) {
      setTimeout(() => {
        setIsOpenTost(false);
      }, 8000);
    }
  }, [isOpenTost]);

  let getAverageRGB = async (img) => {
    const dealer_code = localStorage.getItem("dealer_name") ? JSON.parse(localStorage.getItem("dealer_name")).split(' - ')[1] : "";
    const colour_name = JSON.parse(localStorage.getItem("colour"));
    const paintCategory = JSON.parse(localStorage.getItem("paintCategory"));
    let dealer_password = localStorage.getItem("dealer_passsword");
    const manufacturer_name = JSON.parse(localStorage.getItem("paintManufacturer"));
    
    const headersList = {
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      "dealer_code": dealer_code,
      "dealer_password": dealer_password,
      "data": {
        "colour_name": colour_name,
        "image_path": img.src,
        "paint_category_name": paintCategory,
        "manufacturer_name": manufacturer_name
      }
    });

    const reqOptions = {
      url: `${ApiURL}webhook/dave/maruti_core/predict-formulation`,
      method: "POST",
      headers: headersList,
      data: bodyContent,
    };

    try {
      let response = await axios.request(reqOptions);
      if (response.data && response.data.results && response.data.results.length > 0) {
        if (response.data.results[0].length === 0) {
          setIsShowOpenTintVolume(true);
        } else {
          return response.data.results[0];
        }
      } else {
        console.log("No data found in the response");
        return null;
      }
    } catch (error) {
      console.error("Error in getAverageRGB:", error);
      return null;
    }
  }
  let image = JSON.parse(localStorage.getItem("uploaded_image"));


  useEffect(() => {
    if (location?.state?.type !== "VehicleModel") {
      let damage_image = document.getElementById("damage_image");
      setIsHideDesiredColourFormulation(null);
      damage_image.crossOrigin = "Anonymous";
      if (image && image.startsWith("http:")) {
        image = image.replace("http:", "https:");
        damage_image.src = image;
      }
      damage_image.src = image;
      damage_image.onload = function () {
        setSelectedImage(image);
        getAverageRGB(damage_image).then((rgb) => {
          console.log(rgb, "answer");
          if (rgb) {
            var clr = rgb[0]["rgb_value"];
            var dist = rgb[1];
            setDistance(dist)
            let ff_id = rgb[0]["formulation_id"]
            localStorage.setItem("found_formulation_id", JSON.stringify(ff_id));
            console.log(clr, "clr");
            const color_code = clr; //rgb.r + "," + rgb.g + "," + rgb.b;
            console.log("colour", color_code)
            setMatchedColor(`rgb(${color_code})`);
            setPredictedColor(color_code);
            //setMatchedColor(`rgb(${rgb.r},${rgb.g},${rgb.b})`);
            setPredictionStatus(true);
          } else {
            setIsLoading(false);
            setPredictionStatus(false);
          }
        });
      };
    }
  }, [image]);
  console.log("predicted", predictedColor)
  useEffect(() => {
    if (matchedColor && location?.state?.type !== "VehicleModel") {
      setIsHideDesiredColourFormulation(false);
      setTimeout(() => {
        setIsOpenTost(true);
      }, 10000);
    }
  }, [matchedColor]);


  useEffect(() => {

  }, [localStorage.getItem("uploaded_image")]);

  const onConfirmPress = (showMessage = false) => {
    setIsLoading(true);
    setDataSavedModal(true);
    setChangeMessage(showMessage);
     localStorage.removeItem("volume_paint_formulation");
    localStorage.removeItem("formulation_name");
    localStorage.removeItem("formulation_image1");
    localStorage.removeItem("dealer_paint_id");
    localStorage.removeItem("formulationdetails");
    localStorage.removeItem("paintCategory");
    localStorage.removeItem("vehicle_model");
    localStorage.removeItem("colour");
    localStorage.removeItem("paintManufacturer");
    window.localStorage.removeItem("repair");
    window.localStorage.removeItem("replace");
    window.localStorage.removeItem("panelOptions");
    window.localStorage.removeItem("consumption");
    window.localStorage.removeItem("selectedData");
    window.localStorage.removeItem("volume");
    window.localStorage.removeItem("panelName");




    setTimeout(() => {
      navigate("/");
      setIsLoading(false);
      // localStorage.clear();
      localStorage.setItem("login", JSON.stringify(true));

      setDataSavedModal(false);
    }, 3000);
  };
  const myFormulationDetails = formulationDetails;
  var object1 =
    myFormulationDetails &&
    myFormulationDetails.reduce(
      (obj, item) =>
        item.label &&
        item.value &&
        Object.assign(obj, { [item.label]: item.value }),
      {}
    );

  const myDefaultFormulationDetails = defaultFormulationDetails;
  var object2 =
    myDefaultFormulationDetails &&
    myDefaultFormulationDetails.reduce(
      (obj, item) =>
        item.label &&
        item.value &&
        Object.assign(obj, { [item.label]: item.value }),
      {}
    );
  const isSubmitDisabled = !Object.keys(object1).length && !Object.keys(object2).length;

  let found_formulation_id = JSON.parse(localStorage.getItem("found_formulation_id"));
  const dealer_code = localStorage.getItem("dealer_name") ? JSON.parse(localStorage.getItem("dealer_name")).split(' - ')[1] : "";
  let dealer_password = localStorage.getItem("dealer_passsword");
  const vehicle_model = JSON.parse(localStorage.getItem("vehicle_model"));  
  let apprxDistance=Math.abs(1 - (Math.round(distance) / 100))
  const tint_code = usedPredictedData ? (object1) :(object2);

  const storeLocalData = async (screenName) => {
     const headersList = {
      "Content-Type": "application/json",
    };

    let bodyContent = {
      "dealer_code": dealer_code,
      "dealer_password": dealer_password,
      "data": {
        "formulation_id": found_formulation_id,
        "used_predicted_data": usedPredictedData,
        "vehicle_name": vehicle_model,
        "distance": apprxDistance,
        "image_url": image
      }
    };

    if (!usedPredictedData) {
      bodyContent.data.edited_tint_codes = tint_code;
  }
 
    const reqOptions = {
      url: `${ApiURL}webhook/dave/maruti_core/prediction-feedback`,
      method: "POST",
      headers: headersList,
      data: JSON.stringify(bodyContent),
    };

    try {
      let ans = await axios.request(reqOptions);

      if (ans) {
        window.localStorage.removeItem("volume_paint_formulation");
        window.localStorage.removeItem("formulation_name");
        window.localStorage.removeItem("formulation_image1");
        window.localStorage.removeItem("formulationdetails");
        localStorage.removeItem("found_formulation_id");

        if (screenName === "logout") {
          props?.context?.setAppLocalData({ ...props.context.initialValue });
          window.localStorage.clear();

          setIsLoading(true);
          setTimeout(() => {
            navigate("/");
            setIsLoading(false);
            localStorage.clear();
            localStorage.setItem("login", JSON.stringify(true));
          }, 2000);
        } else {
          props?.context?.setAppLocalData({
            ...props.context.appLocalData,
            ...props.context.initialPaintValue,
          });
        }
        props?.setIsActiveWindow(1);
      }
    } catch (error) {
      console.log("error---", error);
       setOpenAlert(true)
    }
  };

  useEffect(() => {
    if (
      formulationDetails.length === 0 &&
      !isHideDesiredColourFormulation &&
      !isLoading
    ) {
      setIsShowOpenTintVolume(true);
    }
  }, [formulationDetails, isHideDesiredColourFormulation, isLoading]);
 
  const handleConsumptionModel = () => {
    setShowConsumptionComp(true)
    localStorage.setItem("consumption", JSON.stringify(true));
  }

  const handleHideConsumption = () => {
    setShowConsumptionComp(false)
    localStorage.setItem("consumption", JSON.stringify(false));
  }

  useEffect(() => {
    const storedValue = localStorage.getItem("consumption");
    if (storedValue !== null) {
      setShowConsumptionComp(JSON.parse(storedValue));
    }
  }, []);

  return (
    <>
      <Dialog
        open={dataSavedModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="text-green-900">
          {changeMessage ? "Data is not saved" : "Data Saved Successfully"}
        </DialogTitle>
      </Dialog>
      <div className="uppercase text-[12px] lg:text-base md:text-base sm:text-base">
        <div className="absolute top-5 right-5 z-50">

        </div>
        {isLoading && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {showMSG && (
              <p
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "40%",
                  transform: "translate(-50%, -45%)",
                  zIndex: 99,
                  color: "red",
                  padding: "1vw 2vw",
                  backgroundColor: "white",
                  fontWeight: "bold",
                  fontSize: "1vh",
                  borderRadius:"4px"
                }}
              >
                Internet is slow, please wait for a while.
              </p>
            )}
            <AppLoader />
          </div>
        )}
          <div
          className={`${isColourNotMatch || isShowOpenTintVolume ? "blur-sm" : "blur-none"
            }`}
        >
          {panelLoading&&<AppLoader/>}
          <img
            src={require("../../assets/bg-plain.png")}
            className="h-[100vh] w-[100vw]"
          />
          <div className=" font-Oxanium absolute flex top-16 bottom-0 left-0 right-0  items-center flex-col">
            <div className="w-[85%] max-h-[90%] overflow-auto justify-center items-center text-center">
              <div className=" flex justify-between">
                <p className="font-bold text-xs mt-3 sm:text-sm md:text-base lg:text-base">PAINT FORMULATION REPORT</p>
                {/* <Button
                    variant="contained"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#002783",
                    }}
                    onClick={() => {
                      storeLocalData();
                      onConfirmPress();
                    }}
                  >
                    EXIT
                  </Button> */}
                <h1 className=" text-white "><Logout /></h1>
              </div>

              <Box sx={{ flexGrow: 1, marginTop: 4 ,marginRight:1,borderRadius:10}} >
                <Grid container className="bg-[#DCE0E9] rounded-lg ">
                  <Grid
                    item
                    className="sm:border-[#808080] sm:border-r-2 pb-4 pr-4 break-words sm:w-1/4 w-full rounded-md"
                  >
                    <div className="font-bold font-Oxanium ">Dealer Name </div>
                    <div className="text-[#2D3494] font-semibold font-Oxanium">
                      {customerName}
                    </div>
                  </Grid>
                  <Grid
                    item
                    className="sm:border-[#808080] sm:border-r-2 pb-4 pr-4 break-words sm:w-1/4 w-full"
                  >
                    <div className="font-bold font-Oxanium">Vehicle Model / Colour</div>
                    <div className="text-[#2D3494] font-Oxanium font-semibold">
                      {vehicleModel} / {colour}
                    </div>
                  </Grid>
                  {/* <Grid
                  item
                  className="sm:border-[#808080] sm:border-r-2 pb-4 pr-4 break-words sm:w-1/4 w-full"
                >
                  <div className="font-bold">Task ID</div>
                  <div className="text-[#2D3494] font-semibold">MSIL 4477</div>
                </Grid>
                <Grid item className="pb-4 pr-4 break-words sm:w-1/4 w-full">
                  <div className="font-bold">Employee ID</div>
                  <div className="text-[#2D3494] font-semibold">EMP075</div>
                </Grid> */}
                </Grid>
              </Box>
   
             
              <Box sx={{ flexGrow: 1, marginTop: 1,marginRight:1 }}>
                <Grid container className="py-2">
                  <div
                    item
                    className="break-words sm:w-1/3 w-full flex-col flex justify-center"
                  >
                    <div className="rounded-lg font-Oxanium sm:mr-2 pb-5 pt-2 break-words flex-col flex justify-center bg-[#DCE0E9] items-center">
                      <div>Actual Image</div>
                      <img
                        id="damage_image"
                        className="h-[150px] w-[200px] object-contain"
                        src={selectedImage}
                      />

                      {/* <img id="damage_image" className="h-[150px] w-[200px] object-contain" /> */}
                    </div>
                  </div>
                  {formulationDetails.length !== 0 &&
                    isHideDesiredColourFormulation === false && (
                      <div
                        item
                        className="break-words sm:w-1/3 w-full flex-col flex justify-center"
                      >
                        <div className="rounded-lg sm:mx-2 pb-5 pt-2 break-words flex-col flex mt-4  justify-center bg-[#DCE0E9] items-center">
                          <div>Formulated Shade Card</div>
                          {/* <div
                                        className='h-[150px] w-[200px] bg-[#c0c0c0]'
                                    /> */}

                          <div
                            className="h-[150px] w-[200px]"
                            style={{ backgroundColor: matchedColor }}
                          />
                        </div>
                      </div>
                    )}
                  {(formulationDetails.length === 0 ||
                    isHideDesiredColourFormulation === null) && (
                      <div
                        item
                        className="break-words sm:w-1/3 w-full flex-col flex justify-center"
                      >
                        <div className="sm:ml-2 pb-5 pt-2 break-words flex-col flex justify-center bg-[#e9e9e9] items-center">
                          {/* <div>Accuracy</div> */}
                          <div className="h-[150px] w-[200px] bg-white">
                            <div className="justify-center items-center flex flex-col h-full font-semibold">
                              SMART XR VALIDATION
                              <div className="text-[#2D3494] font-bold">
                                {" "}
                                No Match
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </Grid>
              </Box>
              {isHideDesiredColourFormulation === false && (
                <div className="mr-2 ">
                  {formulationDetails.length !== 0 && (
                    <div className=" rounded-md font-Oxanium w-full py-2 bg-[#2D3494] pl-2 text-white text-left font-semibold">
                      DESIRED COLOUR FORMULATION
                    </div>
                  )}
                  <PaintFormulation
                    setIsLoading={setIsLoading}
                    setIsEditableValue={setIsEditableValue}
                    isShowEditableValue={isShowEditableValue}
                    isEditTableValue={isEditTableValue}
                    isEditableValue={isEditableValue}
                    formulationDetails={formulationDetails}
                    setFormulationDetails={setFormulationDetails}
                    setDefaultFormulationDetails={setDefaultFormulationDetails}
                    defaultFormulationDetails={defaultFormulationDetails}
                     setIsTableEmpty={setIsTableEmpty}
                  />
                   {formulationDetails.length !== 0 &&
                isHideDesiredColourFormulation === false && (
                  <>
                    {showConsumptionComp || pCunsumptionPop ?
                      <div className=" rounded-md  mt-4 py-2 bg-[#2D3494]   flex-row text-white text-left font-semibold flex  sm:flex sm:flex-row  items-center justify-around  sm:mb-0">
                        <div className="text-[10px] md:text-sm lg:text-base sm:text-md ml-4">
                        <h1>ESTIMATE THE PAINT CONSUMPTION?</h1> 
                         </div>
                        <div className="flex text-sm">
                           <Button
                            variant="contained"
                            sx={{
                              color: "#fff",
                              backgroundColor: "#002783",
                            }}
                            onClick={handleConsumptionModel}
                          >
                            YES
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              color: "#002783",
                              backgroundColor: "#fff",
                              marginLeft: 1,
                              marginRight:1
                            }}
                            disabled={isColourNotMatch}
                            onClick={() => {
                              setUsedPredictedData(true);
                              storeLocalData();
                              onConfirmPress();
                              setIsColourNotMatch(false);
                            }}
                           >
                            Skip
                          </Button>
                        </div>
                      </div>
                      :
                      <div className=" rounded-md font-Oxanium w-full mt-4 py-2 bg-[#2D3494] pl-2 text-white text-left font-semibold flex sm:flex sm:flex-row flex-col items-center justify-around  sm:mb-0">
                        COLOUR MATCH
                        <div>
                          <Button
                            variant="contained"
                            sx={{
                              color: "#fff",
                              backgroundColor: "#002783",
                            }}
                            onClick={() => {
                              setPcumsumptionPop(true)
                            }}
                          >
                            YES
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              color: "#002783",
                              backgroundColor: "#fff",
                              marginLeft: 2,
                            }}
                            disabled={isColourNotMatch}
                            onClick={() => {
                              bottomRef.current?.scrollIntoView({
                                behavior: "smooth"
                              });
                              setIsEditTableValue(true);
                              setIsColourNotMatch(true);
                              setUsedPredictedData(false);
                            }}
                          >
                            NO
                          </Button>
                        </div>
                      </div>
                    }
                  </>
                )}
                  {showConsumptionComp &&
                    <>
                      <Paint_consumption
                        onHide={handleHideConsumption}
                        formulationdetails={formulationDetails} 
                        apprxDistance={apprxDistance}
                        usedPredictedData={usedPredictedData}
                        onConfirmPress={onConfirmPress}
                        toggleLoading={toggleLoading}
                        />
                     
                    </>
                  }

                  {isEditTableValue && (
                    <div className="m-5">
                      <Button
                        variant="contained"
                        sx={{
                          color: "#fff",
                          backgroundColor: "#002783",
                          marginLeft: 2,
                        }}
                        disabled={isColourNotMatch}
                        onClick={() => {
                          storeLocalData();
                          onConfirmPress();
                          setIsColourNotMatch(true);
                          setUsedPredictedData(false);
                        }}
                      >
                        Submit & Exit
                      </Button>
                    </div>
                  )}
                </div>
              )}

              <IncorrectValueDialog
                setIsEditableValue={setIsEditableValue}
                setIsShowEditableValue={setIsShowEditableValue}
                isEditableValue={isEditableValue}
                isShowEditableValue={isShowEditableValue}
                setIsEditTableValue={setIsEditTableValue}
              />
            </div>
          </div>
        </div>
        {isShowOpenTintVolume && (
          <div className="justify-center text-center flex absolute w-full bottom-0 top-0">
            <div className="w-[45%]">
              <div className="h-full justify-center flex text-center flex-col">
                <div className="w-full py-2 bg-[#2D3494] pl-2 text-white text-left font-semibold rounded-t-lg">
                  Oops!
                </div>
                <div className="w-full bg-[#fff] pl-2 text-black text-center font-semibold pb-10 pt-10 rounded-b-lg text-[8px] lg:text-sm">
                  <p>
                    System was unable to predict <br />
                    the formulation. Do you wish to
                    <br />
                    add the tint values?
                  </p>
                  <div className="flex justify-around  mt-5">
                    <div className="m-2">
                      <Button
                        variant="contained"
                        sx={{
                          color: "#fff",
                          backgroundColor: "#002783",
                        }}
                        onClick={() => {
                          // storeLocalData();
                          onConfirmPress(true);
                        }}
                      >
                        No
                      </Button>
                    </div>
                    <div className="m-2">
                      <Button
                        variant="contained"
                        sx={{
                          color: "#fff",
                          backgroundColor: "#002783",
                          marginRight: 1,
                        }}
                        onClick={() => {
                          setIsEditTableValue(true);
                          setIsColourNotMatch(true);
                          setUsedPredictedData(false);
                        }}
                      >
                        Yes
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isColourNotMatch && (
          <div className="justify-center text-center flex absolute w-full bottom-0 top-0">
            <div className="w-[85%]">
              <div className="h-full justify-center flex text-center flex-col">
                <div className="w-full py-2 bg-[#2D3494] pl-2 text-white text-left font-semibold">
                  Edit Tints & Tint Volume
                </div>
                <div className="h-[350px] sm:h-[450px] overflow-auto bg-white">
                  {tintloading && <AppLoader />}
                  <PaintFormulation
                    setIsLoading={setTintLoading}
                    setIsEditableValue={setIsEditableValue}
                    isShowEditableValue={isShowEditableValue}
                    isEditTableValue={isEditTableValue}
                    isEditableValue={isEditableValue}
                    formulationDetails={formulationDetails}
                    setFormulationDetails={setFormulationDetails}
                    setDefaultFormulationDetails={setDefaultFormulationDetails}
                    defaultFormulationDetails={defaultFormulationDetails}
                    setIsTableEmpty={setIsTableEmpty}
                  />
                  {isEditTableValue && (
                    <div className="flex justify-around">
                      <div className="m-3">
                        <Button
                          startIcon={
                            <div className="flex items-center absolute top-0 bottom-0 left-0 px-1">
                              <ChevronLeftIcon />
                            </div>
                          }
                          sx={{
                            backgroundColor: "#fff",
                            border: "1px solid #3D5A9F",
                            color: "#3D5A9F",
                            marginLeft: 2,
                            paddingLeft: 4,
                            paddingRight: 4,
                          }}
                          onClick={() => {
                            setIsColourNotMatch(false);
                            setIsEditTableValue(false);
                           }}
                        >
                          Back
                        </Button>
                      </div>
                      <div className="m-3">
                        <Button
                          variant="contained"
                          endIcon={
                            <ChevronRightIcon />
                          }
                          sx={{
                            backgroundColor: "#3c5a9f",
                            paddingLeft: 4,
                            paddingLeft: 4,
                          }}
                          onClick={() => {
                            storeLocalData();
                            onConfirmPress();
                            setUsedPredictedData(false);
                          }}
                          disabled={isTableEmpty}
                        >
                          Submit
                        </Button>

                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div ref={bottomRef}></div>
          </div>
        )}
      </div>
    </>
  );
};

export default PaintFormulationReport;
