import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import { ApiURL, AppHeader } from "../../Components/AppHelpers";
import AppLoader from "../../Components/AppLoader";
import TakePhotoDialog from "../../Components/TakePhotoDialog";
import { ReactCrop } from "react-image-crop";
import "./infoModals/infoModal.css";
import useWindowDimensions from "../../hooks/windowDimention";
import { useDebounceEffect } from "./infoModals/useDebounceEffect";
import { canvasPreview } from "./infoModals/canvasPreview";

const videoConstraints = {
  width: 540,
  facingMode: "environment",
  // height: '100%',
};

const CapturePicture = (props) => {
  const webcamRef = useRef(null);
  const navigate = useNavigate();
  const [isOpenCamera, setIsOpenCamera] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [imageFileList, setImageFileList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalTwo, setOpenModalTwo] = useState(false);
  const [isShowTakePhotoDialog, setIsShowTakePhotoDialog] = useState(true);
  const [isVideoPlay, setIsVideoPlay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const[uplaodload,setUploadload]=useState(false)
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [showCroppedImage, setShowCroppedImage] = useState(false);
  const[imgload,setImgLoad]=useState(false)
  const blobUrlRef = useRef("");

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const { mobile } = useWindowDimensions();

  useEffect(() => {
    if (openModal) {
      setTimeout(() => { 
        setOpenModal(false);
      }, 1000);
    }
  }, [openModal]);
  useEffect(() => {
    if (openModalTwo) {
      setTimeout(() => {
        setOpenModalTwo(false);
      }, 2500);
    }
  }, [openModalTwo]);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  useEffect(() => {
    if (imageFileList.length) {
      props.setCarImageFile(imageFileList);
      props.setCarImage(imageList);
      setIsOpenCamera(false);
       localStorage.setItem("uploaded_image", JSON.stringify(imageFileList[0]));
    }
  }, [imageFileList]);

  const uploadCarPicture = async (files) => {
    const formData = new FormData();
    formData.append("file", files);
    // console.log("file upload started", `${ApiURL}/upload-file`, formData, {
    //   headers: AppHeader,
    // });

    if (files) {
      setUploadload(true)
      axios
        .post(`${ApiURL}/upload-file`, formData, { headers: AppHeader })
        .then((res) => {
          if (res) {
            // imageFileList.push(res.data.path)
            setImageFileList([res.data.path]);
            console.log("file upload finished");
            localStorage.setItem(
              "uploaded_image",
              JSON.stringify(res.data.path)
            );
             setUploadload(false)
             setIsLoading(false);
            props.setIsVideoPlay(true);
          }
        })
        .catch((err) => {
          console.log("upload-file error ====>", err);
          setIsLoading(false);
        });
    }
  };
  const uploadGalaryCarPicture = async (files) => {
    // console.log("file upload started", `${ApiURL}/upload-file`, formData, { headers: AppHeader });
    const formData = new FormData();
    formData.append("file", files);
    if (files) {
      setUploadload(true)
      setImgLoad(true)
      axios
        .post(`${ApiURL}/upload-file`, formData, { headers: AppHeader })
        .then((res) => {
          if (res) {
            // imageFileList.push(res.data.path)
            // setImageFileList([res.data.path]);
            console.log("file upload finished");
            // setImageFileList([res.data.path]);
            setImageList([res.data.path]);
            const url = res.data.path;
            setImageFileList([url]);
            // localStorage.setItem(
            //   "uploaded_image",
            //   JSON.stringify(res.data.path)
            // );

            // return res.data.path;

            // console.log("imageFileList===%%%", imageFileList);
            setUploadload(false)
            setIsLoading(false);
            // props.setIsVideoPlay(true);
          }
        })
        .catch((err) => {
          console.log("upload-file error ====>", err);
          setIsLoading(false);
        });
    }
  };

  const withoutCrop = async () => {
    setIsLoading(true);
    let data = await fetch(imageList[imageList.length - 1]);
    let blob = await data.blob();
    try {
      const file = new File([blob], "File name.png", {
        type: "image/png",
      });
       await uploadCarPicture(file);
      setIsLoading(false);
      // setAllowCrop(false);
    } catch (e) {
      console.log(e);
    }
  };

  const capturePhoto = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    // imageList.push(imageSrc)
    setImageList([imageSrc]);
    const url = imageSrc;
    setImageFileList([url]);
    setIsLoading(false);
    // fetch(url)
    //   .then((res) => res.blob())
    //   .then((blob) => {
    //     const file = new File([blob], "File name.png", { type: "image/png" });
    //     setImageFileList([res.data.path]);
    //     // uploadCarPicture(file);
    //   })
    //   .catch((error) => {
    //     console.log("error===", error);
    //     setIsLoading(false);
    //   });
  }, [webcamRef]);

  const onUserMedia = (e) => {};

  const handleCameraModel = () => {
    setIsOpenCamera(!isOpenCamera);
    setIsLoading(true);
  };

  useEffect(() => {
    setIsOpenCamera(true);
    props.setCarImage(null);
  }, []);

  const onSelectOption = (value) => {
    if (value.type === "Camera") {
      capturePhoto();
    } else {
      if (value.reader) {
        let tmpPath = (window.URL || window.webkitURL).createObjectURL(
          value.file
        );

        let array = ["image/jpeg", "image/png", "image/jpg"];
        console.log(value.file.type, array.indexOf(value.file.type) );
        
        if (array.indexOf(value.file.type) == -1) {
          setIsLoading(false);
          alert("Please select valid Image");
          

          return false;
        }
        // setIsLoading(true);

        // return;

        setIsLoading(true);
        // localStorage.setItem("formulation_image1", JSON.stringify(value.reader));
        // imageList.push(value.reader)
        uploadGalaryCarPicture(value.file);
        // setImageList([value.reader]);
        // setImageFileList([tmpPath]);
      }
    }
  };

  const onCropClick = async () => {
    setIsLoading(true);
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }

    previewCanvasRef.current.toBlob(async (blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      // if (blobUrlRef.current) {
      //   URL.revokeObjectURL(blobUrlRef.current);
      // }
      // blobUrlRef.current = URL.createObjectURL(blob);
      //   console.log(blobUrlRef.current);
      try {
        const file = new File([blob], "File name.png", {
          type: "image/png",
        });
         await uploadCarPicture(file);
      } catch (e) {
        console.log(e);
      }

      //   hiddenAnchorRef.current!.href = blobUrlRef.current
      //   hiddenAnchorRef.current!.click()
    });
  };

//  useEffect(() => {
//     // Display the loader initially
//     const loaderTimeout = setTimeout(() => {
//       console.log("loading...");
//       setImgLoad(false);
//     }, 20000);

//     return () => {
//       clearTimeout(loaderTimeout);
//     };
//   }, []);
const handleImageLoad = () => {
  setImgLoad(false);
};
  return (
    <div className="w-full h-[95%] overflow-auto pb-4">
      {isLoading && <AppLoader />}
      {uplaodload && <AppLoader/>}
      {props.carImage ? (
        <div className="flex justify-center bg-black h-[78%] sm:h-[85%] flex-col">
          {imgload && <AppLoader/>}
           {!showCroppedImage && (
           <ReactCrop
              aspect={1}
              className={["cropImage", mobile && "mob"].join(" ")}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
            >
                <img
                  ref={imgRef}
                  src={props.carImage[0]}
                  crossOrigin="anonymous"
                  alt="Screenshot"
                  className="cropIMG"
                  onLoad={handleImageLoad}
                  style={{ display: imgload ? 'none' : 'block' }}
                />
             </ReactCrop>
          )}

          <div
            style={{
              display: showCroppedImage ? "flex" : "none",
              overflow: "hidden",
            }}
            className="cropper"
          >
            <canvas
              ref={previewCanvasRef}
              style={{
                // border: "1px solid black",
                objectFit: "contain",
              }}
              // className="max-h-full"
            />
          </div>
          {/* <img
            src={props.carImage[0]}
            alt="Screenshot"
            className="object-contain max-h-full"
          /> */}
          {/* <img src={'https://images.pexels.com/photos/904117/pexels-photo-904117.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'} alt="Screenshot" className='object-contain' /> */}
        </div>
      ) : (
        <div className="w-full h-[78%] sm:h-[85%] rounded-2xl overflow-hidden">
          <Webcam
            ref={webcamRef}
            audio={false}
            screenshotFormat="image/png"
            videoConstraints={videoConstraints}
            onUserMedia={onUserMedia}
            mirrored={false}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </div>
      )}
      <div className="flex justify-around w-full sm:flex sm:flex-row flex-col">
        <div className="w-full sm:w-[66.66%] flex justify-around items-center">
          <div className="sm:mt-8 mt-4 w-full items-center sm:w-1/4">
            <Button
              variant="contained"
              className="w-full"
              startIcon={
                <div className="flex items-center absolute top-0 bottom-0 left-0 px-1">
                  <ChevronLeftIcon />
                </div>
              }
              onClick={() => {
                props.setCanScanTheCar(false);
              }}
              style={{
                backgroundColor: "#fff",
                border: "1px solid #3D5A9F",
                color: "#3D5A9F",
                paddingLeft: 50,
                paddingRight: 15,
              }}
            >
              Back
            </Button>
          </div>
          {props.carImage ? (
            <div className="sm:mt-8 sm:ml-0 ml-8 mt-4 w-full items-center sm:w-1/4">
              <Button
                variant="contained"
                className="w-full"
                endIcon={
                  <div className="bg-[#1e3e88] rounded-sm flex items-center absolute top-0 bottom-0 right-0 px-2">
                    <CenterFocusWeakIcon className="" />
                  </div>
                }
                sx={{
                  backgroundColor: "#3c5a9f",
                  paddingRight: 6,
                }}
                onClick={() => {
                  props.setCarImage(null);
                  setIsShowTakePhotoDialog(true);
                  setCompletedCrop(undefined);
                  setShowCroppedImage(false);
                  setCrop(null);
                  // handleCameraModel()
                  // onSelectOption({ type: 'Camera' })
                  // capturePhoto()
                }}
              >
                Re-Take Photo
              </Button>
            </div>
          ) : (
            <div className="sm:mt-8 sm:ml-0 ml-8 mt-4 w-full items-center sm:w-1/4">
              <Button
                variant="contained"
                className="w-full"
                endIcon={
                  <div className="bg-[#1e3e88] rounded-sm flex items-center absolute top-0 bottom-0 right-0 px-2">
                    <CenterFocusWeakIcon className="" />
                  </div>
                }
                sx={{
                  backgroundColor: "#3c5a9f",
                  paddingRight: 6,
                }}
                onClick={() => {
                  props.setCarImage(null);
                  handleCameraModel();
                  onSelectOption({ type: "Camera" });
                  // capturePhoto()
                  // setIsShowTakePhotoDialog(true)
                }}
              >
                Take Photo
              </Button>
            </div>
          )}
        </div>
        <div className="sm:mt-8 mt-2 w-full items-center sm:w-1/4">
          <Button
            variant="contained"
            className="w-full"
            endIcon={
              <div className="bg-[#1e3e88] rounded-sm flex items-center absolute top-0 bottom-0 right-0 px-1">
                <ChevronRightIcon />
              </div>
            }
            onClick={() => {
              if (!props.carImage) {
                setOpenModal(true);
              } else {
                if (completedCrop == undefined) {
                  // setOpenModalTwo(true);
                  withoutCrop();
                } else {
                  if (showCroppedImage) {
                    onCropClick();
                  } else {
                    setShowCroppedImage(true);
                  }
                }
              }
            }}
            style={{
              backgroundColor: "#3c5a9f",
              paddingRight: 50,
              paddingLeft: 15,
            }}
          >
       {completedCrop === undefined
      ? "START ANALYSIS"
      : showCroppedImage
      ? "START ANALYSIS"
      : "CROP IMAGE"}
          </Button> 
        </div>
      </div>
      <Dialog
        open={openModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="text-red-500">
          Car Image is Required
        </DialogTitle>
      </Dialog>
      <Dialog
        open={openModalTwo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="text-red-500">
          Please crop first before proceeding.
        </DialogTitle>
      </Dialog>
      <TakePhotoDialog
        isShowTakePhotoDialog={isShowTakePhotoDialog}
        setIsShowTakePhotoDialog={setIsShowTakePhotoDialog}
        onSelectOption={onSelectOption}
      />
    </div>
  );
};

export default CapturePicture;
