import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import Button from "@mui/material/Button";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppLoader from "../../Components/AppLoader";
import { ApiURL } from "../../Components/AppHelpers";
import { AppHeader } from "../../Components/AppHelpers";
const ScanImage = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isVideoPlay, setIsVideoPlay] = useState(false);
  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    let logIn = JSON.parse(localStorage.getItem("login"));
    if (logIn != undefined) {
      if (!logIn) {
        navigate("/log-in");
      }
    } else {
      navigate("/log-in");
    }
  }, []);

  useEffect(() => {
    const uploaded_image = JSON.parse(localStorage.getItem("uploaded_image"));
    if (uploaded_image) {
      setSelectedImage(uploaded_image);
    }
  }, []);

  const onFileChange = (event) => {
    hiddenFileInput.current.click();
    setIsLoading(true);
    const myfile = event.target.files[0];
    const formData = new FormData();
    formData.append("file", myfile);
    axios
      .post(`${ApiURL}upload-file`, formData, { headers: AppHeader })
      .then((res) => {
        if (res) {
           localStorage.setItem("uploaded_image", JSON.stringify(res.data.path));
          setSelectedImage(res.data.path);
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("upload-file error ====>", err);
      });
  };

  const onScanImage = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsVideoPlay(true);
      setIsLoading(false);
    }, 2000);
  };

  const handleVideoEnded = () => {
    setIsVideoPlay(false);
    // navigate("/paint-formulation-report");
    alert("working on this part ")
  };

  return (
    <div className="w-full h-full">
      {isLoading && <AppLoader />}
      {isVideoPlay ? (
        <div className="w-full h-full flex items-center justify-center">
          <video width="750" height="500" autoPlay onEnded={handleVideoEnded}>
            <source src={require("../../assets/movideo.mp4")} type="video/mp4" />
          </video>
        </div>
      ) : (
        <>
          <img
            src={require("../../assets/bg-plain.png")}
            className="h-[100vh] w-[100vw]"
          />
          <div className="absolute flex top-28 bottom-0 left-0 right-0  items-center flex-col">
            <div className="w-[80%] overflow-scroll justify-center items-center text-center">
              <p className="font-bold">SCAN IMAGE</p>
              <div className="justify-center items-center flex">
                <img
                  className="max-h-[400px] w-auto rounded-lg mt-3 object-contain"
                  src={selectedImage}
                />
              </div>
              <div
                id="start"
                onClick={() => {
                  onScanImage();
                }}
                className="mt-1 text-[#2d3594] font-bold cursor-pointer"
              >
                START ANALYSIS
              </div>
              {/* <input type="file" id="imagePicker" accept="image/*" /> */}
              <div>
                <Button
                  variant="outlined"
                  endIcon={
                    <div className="bg-[#c7ccd6] flex items-center absolute top-0 bottom-0 right-0 px-2">
                      <CenterFocusWeakIcon />
                    </div>
                  }
                  sx={{
                    color: "#2d3594",
                    border: "1px solid #2d3594",
                    backgroundColor: "#dfe0e1",
                    paddingRight: 6,
                    marginTop: 5,
                  }}
                  onClick={() => {
                    handleClick();
                  }}
                >
                  ReTake Photo
                </Button>
                {/* <input type="file" onChange={onFileChange} /> */}
                <input
                  type="file"
                  accept="image/png,image/jpg,image/jpeg"
                  ref={hiddenFileInput}
                  onChange={onFileChange}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ScanImage;
