import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiURL, AppHeader } from "../../Components/AppHelpers";
import Logout from "../logoutpopup";
import AppLoader from "../../Components/AppLoader";
import '../../index.css'
function PaintSelection(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [paintManufacturerData, setPaintManufacturerData] = useState(["Loading..."]);
  const [paintManufacturer, setPaintManufacturer] = useState("");
  const [dealerRegionData, setDealerRegionData] = useState([]);
  const [dealerRegion, setDealerRegion] = useState("");
  const [dealerCityData, setDealerCityData] = useState([]);
  const [dealerCity, setDealerCity] = useState("");
  const [dealerNameData, setDealerNameData] = useState([]);
  const [dealerName, setDealerName] = useState("");
  const [paintCategoryData, setPaintCategoryData] = useState(["Loading..."]);
  const [paintCategory, setPaintCategory] = useState("");
  const [colorData, setColorData] = useState([]);
  const [vehicleData, setVehicleData] = useState([])
  const[vehicleLoading,setVehicleLoading]=useState(false)
  const[categoryLoading,setCategoryLoading]=useState(false)
  const[colourDataLoading,setColourDataLoading]=useState(false)


  const paintManufacturer_data = JSON.parse(
    localStorage.getItem("paintManufacturer")
  );
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    setIsIOS(/iPad|iPhone|iPod/.test(navigator.userAgent));
  }, []);

  const iosStyles = {
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  };
 
  useEffect(() => {
    setColourDataLoading(true)
    axios
      .get(
        `${ApiURL}pivot/paint_formulation/colour_name?_fresh=true&colour_name~=null`,
        { headers: AppHeader }
      )
      .then((res) => {
        if (res) {
          var colourName = [];
          var key = Object.keys(res.data.data);
          for (var name of key) {
            colourName.push(name);
          }
          colourName.sort()
          setColorData(colourName);
          setColourDataLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${ApiURL}pivot/paint_formulation/manufacturer_name?_fresh=True&original_paint_formulation=false&page_size=50`,
        { headers: AppHeader }
      )
      .then((res) => {
        if (res) {
          var manufacturer_name = [];
          var key = Object.keys(res.data.data);
          for (var name of key) {
            manufacturer_name.push(name);
          }
          manufacturer_name.sort()
          setPaintManufacturerData(manufacturer_name);
          setIsLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    let logIn = JSON.parse(localStorage.getItem("login"));
    if (logIn != undefined) {
      if (!logIn) {
        navigate("/log-in");
      }
    } else {
      navigate("/log-in");
    }
  }, []);

  const dealerRegionName = JSON.parse(localStorage.getItem("dealer_region"))
  const dealerCityName = JSON.parse(localStorage.getItem("dealer_city"));
  const dealerNamelo = JSON.parse(localStorage.getItem("dealer_name"))||"";
  const vehicle_model = JSON.parse(localStorage.getItem("vehicle_model"));
  const paint_manufacturer = JSON.parse(localStorage.getItem("paintManufacturer"));
  const paint_category = JSON.parse(localStorage.getItem("paintCategory"));
  const colour = JSON.parse(localStorage.getItem("colour"));


  useEffect(() => {
    axios
      .get(
        `${ApiURL}/objects/dealer_paint_formulation?dealer_code=${dealerNamelo.split(" - ")[1]
        }`,
        { headers: AppHeader }
      )
      .then((res) => {
        if (res) {
          let data = res.data.data;

          localStorage.setItem("dealer_passsword", data[0].dealer_password);
        }
      }).catch((err) => {
        console.log(err)
      });
  }, [dealerNamelo])


  useEffect(() => {
     setCategoryLoading(true)
    axios
      .get(
        `${ApiURL}pivot/paint_formulation/paint_category_name?manufacturer_name=${paintManufacturer_data}&_fresh=True&original_paint_formulation=false`,
        { headers: AppHeader }
      )
      .then((res) => {
        if (res) {
          var category_name = [];
          var key1 = Object.keys(res.data.data);
          for (var category of key1) {
            category_name.push(category);
          }
          setPaintCategory("");
          setPaintCategoryData(category_name);
          setCategoryLoading(false)

        }
      });
  }, [paintManufacturer]);

  let page_size = 50;
  let pageNumber = 1;
  
  useEffect(() => {
    function fetchData() {
      setVehicleLoading(true)
      axios.get(`${ApiURL}pivot/paint_consumption/vehicle_model?_fresh=True&page_size=${page_size}&page_number=${pageNumber}`,
        { headers: AppHeader }
      ).then(response => {
        let res = response.data;
         if (res.data) {
          var vehicleNames = [];
          var d_r = Object.keys(res.data);
          d_r.sort();
           for (var name of d_r) {
            vehicleNames.push(name);
          }
           setVehicleData(prevData => [...prevData, ...vehicleNames]);  
           if (!res.is_last) {
             pageNumber++;
            fetchData();
          }
          setVehicleLoading(false)
        } else {
          console.log("No data found");
        }
      }).catch(error => {
        console.error('Axios error:', error);
        setVehicleLoading(false)

      });
    }
    setVehicleData([])
    fetchData();
  }, []);
  
   return (
    <div className="">
      {isLoading && <AppLoader />}
      <img
        src={require("../../assets/bg.png")}
        className="h-[100vh] w-[100vw]"
      />
      <div className="absolute flex top-0 bottom-0 left-0 right-0 justify-center items-center flex-col">
        <div className="w-[80%] h-[60%] overflow-y-scroll justify-center items-center text-center " style={isIOS ? iosStyles : {}}>
          <Formik
            initialValues={{
              paint_manufacturer: paint_manufacturer || "",
              paint_category: paint_category || "",
              dealer_region: "",
              dealer_name: "",
              dealer_city: "",
              formulation_name: "",
              colour: colour || "",
              // customer_name: '',
              vehicle_model: vehicle_model || "",
            }}
            validate={(values) => {
              const errors = {};
              var specialCharError = /^[a-zA-Z0-9 ]*$/;
              if (!values.paint_manufacturer) {
                errors.paint_manufacturer = "Paint Manufacturer is Required";
              }
              if (!values.paint_category) {
                errors.paint_category = "Paint Category is Required";
              }

              if (!values.colour) {
                errors.colour = "Colour is Required";
              }
              if (!values.vehicle_model) {
                errors.vehicle_model = "Vehicle Name  is Required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                navigate("/scan-the-car");
                setSubmitting(false);
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => {
              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div className="items-center flex flex-col ">
                    {/* <img src={Logo} className='w-5/6' /> */}
                    <div className="rounded-lg sm:w-[65%] w-[90%] items-center flex flex-col py-4">
                      <div
                        className="flex flex-row sm:flex-row items-center mt-3 mb-3"
                        style={{ border: "1px solid #1e3e88" }}
                      >
                        <p className="p-2">Already have the result?</p>
                        <div
                          onClick={() => {
                            navigate("/vehicle-model");
                          }}
                          className="p-2 cursor-pointer sm:text-sm text-white bg-[#1e3e88]"
                        >
                          VALIDATE
                          <ChevronRightIcon />
                        </div></div>
                      <div className="w-[90%] text-left uppercase mb-1 mt-2 text-[#9d9fa2]">
                        Dealer Region<span>*</span>
                      </div>
                      <Autocomplete
                        required
                        freeSolo
                        id="dealer_region"
                        name="dealer_region"
                        options={dealerRegionData.map((option) => option)}
                        disabled={true}
                        className="w-[90%] bg-[#9d9fa2] cursor-not-allowed"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },

                          "&:hover .MuiAutocomplete-input": {
                            borderColor: "red",
                            cursor: "not-allowed"
                          },
                        }}
                        onBlur={handleBlur}
                        value={dealerRegionName}
                        onChange={(event, value) => {
                          setFieldValue("dealer_region", value);
                          setFieldValue("dealer_city", "");
                          setFieldValue('dealer_name', '');
                          localStorage.setItem(
                            "dealer_region",
                            JSON.stringify(value)
                          );

                          setDealerRegion(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="cursor-not-allowed"
                            {...params}
                            placeholder="Enter Dealer Region"

                          />
                        )}
                      />
                      <div className="w-[90%] text-left uppercase mb-1 mt-2 text-[#9d9fa2]">
                        Dealer City<span>*</span>
                      </div>
                      <Autocomplete
                        required
                        freeSolo
                        id="dealer_city"
                        name="dealer_city"
                        disabled={true}
                        options={dealerCityData.map((option) => option)}
                        className="w-[90%] bg-[#9d9fa2]"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiAutocomplete-input": {
                            borderColor: "red",
                            cursor: "not-allowed"
                          },
                        }}
                        onBlur={handleBlur}
                        value={dealerCityName}
                        onChange={(event, value) => {
                          setFieldValue("dealer_city", value);
                          setFieldValue("dealer_name", '');
                          localStorage.setItem(
                            "dealer_name",
                            ''
                          );
                          localStorage.setItem(
                            "dealer_city",
                            JSON.stringify(value)
                          );
                          setDealerCity(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Enter Dealer City"
                          />
                        )}
                      />
                      <div className="  w-[90%] text-left uppercase mb-1 mt-2 text-[#9d9fa2]">
                        Dealer Name<span>*</span>
                      </div>
                      <Autocomplete
                        required
                        freeSolo
                        id="dealer_name"
                        name="dealer_name"
                        disabled={true}
                        options={dealerNameData.map((option) => option)}
                        className="w-[90%] bg-[#9d9fa2]"
                        sx={{
                          "&:hover .MuiAutocomplete-input": {
                            cursor: "not-allowed",
                            overflow: "visible",
                          },
                          "& .MuiAutocomplete-inputRoot": {
                            overflow: "hidden",
                          },
                          "& .MuiAutocomplete-input": {
                            whiteSpace: "nowrap",
                            textOverflow: "unset",
                            overflow: "hidden",
                          },
                        }}
                        onBlur={handleBlur}
                        value={dealerNamelo}
                        onChange={(event, value) => {
                          setFieldValue("dealer_name", value);
                          localStorage.setItem("dealer_name", JSON.stringify(value));
                          setDealerName(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Enter Dealer Name"
                          />
                        )}
                      />

                      <div className="w-[90%] text-left uppercase mb-1 mt-1 text-[#9d9fa2] z-10 ">
                          VEHICLE MODEL<span>*</span>
                      </div>

                      <Autocomplete
                        required
                        name="vehicle_model"
                        id="vehicle_model"
                        options={vehicleData}
                        loading={vehicleLoading}
                        loadingText='Loading...'
                        className="w-[90%] bg-[#9d9fa2]"
                        style={{
                          zIndex: isLoading ? -1 : 1,
                        }}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input": {
                            cursor: values.vehicle_model === "Loading..." ? "not-allowed" : "auto",
                          },
                          "& .MuiAutocomplete-input": {
                            whiteSpace: "nowrap",
                            textOverflow: "unset",
                            overflow: "hidden",
                          },

                        }}
                        onBlur={handleBlur}
                        value={values.vehicle_model}
                        onChange={(event, value) => {
                          setFieldValue("vehicle_model", value);
                          localStorage.setItem(
                            "vehicle_model",
                            JSON.stringify(value)
                          );
                        }}
                        getOptionDisabled={(option) => option === "Loading..."}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Vehicle Name"
                            disabled={values.vehicle_model === "Loading..."}

                          />
                        )}
                      />

                      <div className="text-red-500 w-[90%] text-left text-sm capitalize">
                        {errors.vehicle_model &&
                          touched.vehicle_model &&
                          errors.vehicle_model}
                      </div>


                      <div className="w-[90%] text-left uppercase mb-1 mt-2 text-[#9d9fa2]">
                        Paint manufacturer<span>*</span>
                      </div>
                      <Autocomplete
                        required
                        id="paint_manufacturer"
                        name="paint_manufacturer"
                        options={paintManufacturerData}
                         className="w-[90%] bg-[#9d9fa2]"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
    
                        }}
                        onBlur={handleBlur}
                        value={values.paint_manufacturer}
                        onChange={(event, value) => {
                          if (paint_manufacturer === null) {
                            setPaintCategory("");
                            setPaintCategoryData([]);
                            setFieldValue("paint_category", "");
                            localStorage.removeItem("paintCategory");
                          } else {
                            setPaintCategory("");
                            setPaintCategoryData([]);
                            setFieldValue("paint_category", "");
                            localStorage.removeItem("paintCategory");
                          }
                          setFieldValue("paint_manufacturer", value);
                          localStorage.setItem("paintManufacturer", JSON.stringify(value));
                          setPaintManufacturer(value);
                        }}
                        getOptionLabel={(option) => option}
                         renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Paint Manufacturer"
                           />
                        )}
                      />
                      <div className="text-red-500 w-[90%] text-left text-sm capitalize">
                        {errors.paint_manufacturer &&
                          touched.paint_manufacturer &&
                          errors.paint_manufacturer}
                      </div>
                      <div className="w-[90%] text-left uppercase mb-1 mt-2 text-[#9d9fa2]">
                        colour<span>*</span>
                      </div>
                      <Autocomplete
                        required
                        id="colour"
                        name="colour"
                        // value={color}
                        options={colorData.map((option) => option)}
                        loadingText="Loading..."
                        loading={colourDataLoading}
                        className="w-[90%] bg-[#9d9fa2]"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiAutocomplete-input": {
                            whiteSpace: "nowrap",
                            textOverflow: "unset",
                            overflow: "hidden",
                          },
                        }}
                        onBlur={handleBlur}
                        value={values.colour}
                        onChange={(event, value) => {
                          // console.log("Selected color:", value);

                          let colorId;

                          if (value === "SILKY SILVER") {
                            colorId = 'Z2S';
                          } else if (value === "METALLIC GRANITE GREY"||value==="MET.GRANITE GREY") {
                            colorId = 'ZTN';
                          } else if (value === "METALLIC GLISTENIG GREY") {
                            colorId = 'ZNZ';
                          } else {
                            console.log("Unexpected color:", value);
                          }
                          localStorage.setItem("colour", JSON.stringify(value));
                          localStorage.setItem("color_Id", JSON.stringify(colorId));

                          setFieldValue("colour", value);
                        }}

                        renderInput={(params) => (
                          <TextField {...params} placeholder="Select Colour" />
                        )}
                      />
                      <div className="text-red-500 w-[90%] text-left text-sm capitalize">
                        {errors.colour && touched.colour && errors.colour}
                      </div>
                      <div className="w-[90%] text-left uppercase mb-1 mt-2 text-[#9d9fa2]">
                        Paint Category<span>*</span>
                      </div>
                      <Autocomplete
                        required
                        id="paint_category"
                        name="paint_category"
                        key="paint_category"
                        options={paintCategoryData}
                        loading={categoryLoading}
                        loadingText="Loading..."
                        className="w-[90%] bg-[#9d9fa2]"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input": {
                            cursor: values.paint_category === "Loading..." ? "not-allowed" : "auto",
                          },
                        }}
                        onBlur={handleBlur}
                        value={values.paint_category}
                        onChange={(event, value) => {
                          setPaintCategory(value);
                          setFieldValue("paint_category", value);
                          localStorage.setItem(
                            "paintCategory",
                            JSON.stringify(value)
                          );
                        }}
                        getOptionDisabled={(option) => option === "Loading..."}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Paint Category"
                            disabled={values.paint_category === "Loading..."}

                          />
                        )}
                      />
                      <div className="text-red-500 w-[90%] text-left text-sm capitalize">
                        {errors.paint_category &&
                          touched.paint_category &&
                          errors.paint_category}
                      </div>

                    </div>
                    <div className="flex justify-between items-center sm:items-baseline w-[80%] sm:w-[60%] my-6 sm:flex sm:flex-row flex-col">
                      <div className="w-full items-center sm:w-1/3 mt-2 sm:mt-8">
                        <Button
                          variant="contained"
                          className="w-full"
                          startIcon={
                            <div className="flex items-center absolute top-0 bottom-0 left-0 px-1">
                              <ChevronLeftIcon />
                            </div>
                          }
                          onClick={() => {
                            navigate("/");
                          }}
                          style={{
                            backgroundColor: "#fff",
                            border: "1px solid #3D5A9F",
                            color: "#3D5A9F",
                            paddingLeft: 40,
                            paddingRight: 15,
                          }}
                        >
                          Back
                        </Button>
                      </div>
                      <div className="w-full items-center sm:w-1/3 mt-4 sm:mt-10">
                        <Button
                          variant="contained"
                          className="w-full"
                          endIcon={
                            <div className="bg-[#1e3e88] rounded-sm flex items-center absolute top-0 bottom-0 right-0 px-1">
                              <ChevronRightIcon />
                            </div>
                          }
                          type="submit"
                          disabled={isSubmitting}
                          style={{
                            backgroundColor: "#3c5a9f",
                            paddingLeft: 40,
                            paddingLeft: 15,

                          }}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                    <div className="text-gray-500">
                      <Logout className="text-gray-500" />
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default PaintSelection;
