import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { Formik } from "formik";
import produce from "immer";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../../assets/images";
import { ApiURL, AppHeader } from "../../../Components/AppHelpers";
import WithAppContext from "../../../Helpers/context/appContext/appContext";
import { ChevronLeftSharp } from "@mui/icons-material";
import "../Components/paint.css";
import { logOutClearData } from "./AddFormulationDetails";

function FormulationDetails(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [modelNameData, setModelNameData] = useState([]);
  const [modelName, setModelName] = useState("");
  const [paintManufacturer, setPaintManufacturer] = useState([]);
  const [colorData, setColorData] = useState(["SILKY SILVER", "METALLIC GRANITE GREY", "METALLIC GLISTENIG GREY"]);
  const [paintManufacturerData, setPaintManufacturerData] = useState([]);
  const [color, setColor] = useState("");
  const [modelFormulationNameData, setModelFormulationNameData] = useState([]);
  const [modelFormulationName, setModelFormulationName] = useState("");
  const [paintCategoryData, setPaintCategoryData] = useState(["Solvent Borne", "Water Borne"]);
  const [paintCategory, setPaintCategory] = useState("");
  const [initialValues, setInitialValues] = useState({});
  const [logoutModal, setLogOutModal] = useState(false);

  useEffect(() => {
    if (props.context.appLocalData) {
      if (props.context.appLocalData.PaintFormulationDetails) {
        setInitialValues(props.context.appLocalData.PaintFormulationDetails);
      }
      setIsLoading(false);
    }
  }, [props.context]);

  useEffect(() => {
    axios
      .get(`${ApiURL}pivot/product/product_name?_fresh=True`, {
        headers: AppHeader,
      })
      .then((res) => {
        if (res) {
          var product_list = [];
          var product_data = Object.keys(res.data.data);
          for (var product of product_data) {
            product_list.push(product);
          }
          setModelNameData(product_list);
          setIsLoading(false);
        }
      });

    axios
      .get(`${ApiURL}pivot/paint_formulation/manufacturer_name?_fresh=True`, {
        headers: AppHeader,
      })
      .then((res) => {
        if (res) {
          var manufacturer_name = [];
          var key = Object.keys(res.data.data);
          for (var name of key) {
            manufacturer_name.push(name);
          }
          setPaintManufacturerData(manufacturer_name);
        }
      });
  }, []);
  // useEffect(() => {
  //   const paintManufacturer = JSON.parse(
  //     localStorage.getItem("paintManufacturer")
  //   );
  //   if (paintManufacturer !== null) {
  //     axios
  //       .get(
  //         `${ApiURL}pivot/paint_formulation/paint_category_name?manufacturer_name=${paintManufacturer}&_fresh=True`,
  //         { headers: AppHeader }
  //       )
  //       .then((res) => {
  //         if (res) {
  //           var category_name = [];
  //           var key1 = Object.keys(res.data.data);
  //           for (var category of key1) {
  //             category_name.push(category);
  //           }
  //           setPaintCategory("");
  //           setPaintCategoryData(category_name);
  //         }
  //       });
  //   }
  // }, [paintManufacturer]);
  useEffect(() => {
    const paintManufacturer = JSON.parse(
      localStorage.getItem("paintManufacturer")
    );
    if (paintManufacturer) {
      setPaintManufacturer(paintManufacturer);
      setIsLoading(false);
    }
  }, []);
  // useEffect(() => {
  //   const paintManufacturer = JSON.parse(
  //     localStorage.getItem("paintManufacturer")
  //   );
  //   // console.log(paintManufacturer);
  //   // if (paintManufacturer != null) {
  //     axios
  //       .get(
  //         `${ApiURL}pivot/paint_formulation/colour_name?manufacturer_name=${paintManufacturer}&colour_name~=null&_fresh=True`,
  //         { headers: AppHeader }
  //       )
  //       .then((res) => {
  //         console.log(res, "lasldklasjdlkajsk");
  //         if (res) {
  //           var color_list = [];
  //           var color_data = Object.keys(res.data.data);
  //           for (var color of color_data) {
  //             color_list.push(color);
  //           }
  //           // setColorData(color_list);
  //         console.log(color_list, "lasldklasjdlkajsk");

  //         }
  //       });
  //   // }
  // }, [paintManufacturer]);

  // useEffect(() => {
  //     const paintCategory = JSON.parse(localStorage.getItem('paintCategory'));
  //     if (paintCategory) {
  //         setPaintCategory(paintCategory);
  //     }
  // })

  useEffect(() => {
    if (paintManufacturer != null && color != null && paintCategory != null) {
      axios
        .get(
          `${ApiURL}objects/paint_formulation?manufacturer_name=${paintManufacturer}&colour_name=${color}&paint_category_name=${paintCategory}`,
          { headers: AppHeader }
        )
        .then((res) => {
          if (res) {
            setModelFormulationName("");
            setModelFormulationNameData(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [paintManufacturer, color, paintCategory]);

  if (isLoading) {
    return (
      <div className="h-full w-full justify-center items-center flex">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
    <Formik
      initialValues={initialValues}
      className="bg-[#f3f3f3]"
      validate={(values) => {
        const errors = {};
        if (!values.colour) {
          errors.colour = "Colour is Required";
        }
        if (!values.paint_category) {
          errors.paint_category = "Paint Category is Required";
        }
        if (!values.paint_manufacturer){
            errors.paint_manufacturer="Paint Manufacturer is Required";
        }
        // if (!values.model_year_and_formulation_name) {
        //     errors.model_year_and_formulation_name = 'Model Year / Formulation Name is Required';
        // }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          const nextState = produce(props.context.appLocalData, (draft) => {
            draft.PaintFormulationDetails = values;
            return draft;
          });
          props.context.setAppLocalData(nextState);
          props.setIsActiveWindow(2);
          setSubmitting(false);
        }, 400);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit} autocomplete="off">
            <div className="items-center flex flex-col pt-14">
              <img src={Logo} className="w-5/6" />
              <div className="bg-[#e5e9f2] rounded-lg w-[90%] items-center flex flex-col py-4">
                <div className="w-[90%] uppercase mb-1 text-[#9d9fa2]">
                  Model Name
                </div>
                <Autocomplete
                  id="model_name"
                  name="model_name"
                  defaultValue={values.model_name}
                  options={modelNameData.map((option) => option)}
                  className="w-[90%] bg-[#9d9fa2]"
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  }}
                  onBlur={handleBlur}
                  onChange={(event, value) => {
                    setModelName(value);
                    setFieldValue("model_name", value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Enter Model Name" />
                  )}
                />

                
                <div className="w-[90%] uppercase mb-1 mt-4 text-[#9d9fa2]">
                  Paint Manufacturer<span>*</span>
                </div>
                <Autocomplete
                  required
                  id="paint_manufacturer"
                  name="paint_manufacturer"
                  defaultValue={values.paint_manufacturer}
                  options={paintManufacturerData.map((option) => option)}
                  className="w-[90%] bg-[#9d9fa2]"
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  }}
                  onBlur={handleBlur}
                  onChange={(event, value) => {
                    setFieldValue("paint_manufacturer", value);
                    setPaintManufacturer(value);
                    localStorage.setItem(
                      "paintManufacturer",
                      JSON.stringify(value)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Enter Paint Manufacturer"
                    />
                  )}
                />
                <div className="text-red-500 w-[90%]">
                  {errors.paint_manufacturer &&
                    touched.paint_manufacturer &&
                    errors.paint_manufacturer}
                </div>
                <div className="w-[90%] uppercase mb-1 mt-4 text-[#9d9fa2]">
                  colour<span>*</span>
                </div>
                <Autocomplete
                  id="colour"
                  name="colour"
                  required
                  defaultValue={values.colour}
                  options={colorData.map((option) => option)}
                  className="w-[90%] bg-[#9d9fa2]"
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  }}
                  onBlur={handleBlur}
                  onChange={(event, value) => {
                    localStorage.setItem("color_name", JSON.stringify(value));
                    setColor(value);
                    setFieldValue("colour", value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Enter Color Name" />
                  )}
                />
                <div className="text-red-500 w-[90%]">
                  {errors.colour && touched.colour && errors.colour}
                </div>
                <div className="w-[90%] uppercase mb-1 mt-4 text-[#9d9fa2]">
                  Paint Category<span>*</span>
                </div>
                <Autocomplete
                  required
                  id="paint_category"
                  name="paint_category"
                  key="paint_category"
                  defaultValue={values.paint_category}
                  options={paintCategoryData.map((option) => option)}
                  className="w-[90%] bg-[#9d9fa2]"
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  }}
                  onBlur={handleBlur}
                  onChange={(event, value, value1) => {
                    setPaintCategory(value);
                    setFieldValue("paint_category", value);
                    localStorage.setItem(
                      "paintCategory",
                      JSON.stringify(value)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Enter Paint Category" />
                  )}
                />
                <div className="text-red-500 w-[90%]">
                  {errors.paint_category &&
                    touched.paint_category &&
                    errors.paint_category}
                </div>
                <div className="w-[90%] uppercase mb-1 mt-4 text-[#9d9fa2]">
                  Model Year / Formulation Name
                </div>
                <TextField
                  id="model_year_and_formulation_name"
                  name="model_year_and_formulation_name"
                  pattern="[0-9]*"
                  value={values.model_year_and_formulation_name}
                  className="w-[90%] bg-[#9d9fa2]"
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  }}
                  onBlur={handleBlur}
                  placeholder="Enter Model Year / Formulation Name"
                  onChange={(event, value) => {
                    localStorage.setItem(
                      "formulation_name",
                      JSON.stringify(event.target.value)
                    );
                    setFieldValue(
                      "model_year_and_formulation_name",
                      event.target.value
                    );
                    setModelFormulationName(value);
                  }}
                />
                {/* <Autocomplete
                                    id="model_year_and_formulation_name"
                                    name="model_year_and_formulation_name"
                                    value={modelFormulationName}
                                    options={modelFormulationNameData.map((option) => option.formulation_name)}
                                    className='w-[90%] bg-[#9d9fa2]'
                                    sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" } }}
                                    onBlur={handleBlur}
                                    onChange={(event, value) => {
                                        localStorage.setItem("formulation_name", JSON.stringify(value))
                                        setModelFormulationName(value)
                                        setFieldValue('model_year_and_formulation_name', value);
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        placeholder="Enter Model Year / Formulation Name"
                                    />}
                                /> */}
              </div>
              <div style={{
                display: 'flex',
                width: '90%',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '5vh',
                marginBottom: '5vh'
              }}>
                <Button
                  variant="outlined"
                  endIcon={
                    <div>
                      <ChevronLeftSharp />
                    </div>
                  }
                  onClick={()=>{
                    window.open("/paint-selection").focus()
                  }}
                  sx={{
                    color: "#002783",
                    border: "1px solid #002783",
                  }}
                //   type="submit"
                  disabled={isSubmitting}
                  // disabled={modelFormulationName === '' && true}
                >
                  Back
                </Button>
                <a
                  onClick={() => {
                    setLogOutModal(true);
                  }}
                  style={{
                    color: "#3c5a9f",
                    marginTop: "5vh",
                  }}
                >
                  LOGOUT
                </a>
                <Button
                  variant="outlined"
                  endIcon={
                    <div>
                      <ChevronRightIcon />
                    </div>
                  }
                  sx={{
                    color: "#002783",
                    border: "1px solid #002783",
                  }}
                  type="submit"
                  disabled={isSubmitting}
                  // disabled={modelFormulationName === '' && true}
                >
                  Next
                </Button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
    {logoutModal && (
        <div className="logoutModal">
          <div>
            <p>Are you sure you want to logout?</p>
            <div className="buttons">
              <button onClick={()=>{
                setLogOutModal(false);
              }}>CANCEL</button>
              <a onClick={()=>{
                logOutClearData();
              }} href="/">LOGOUT</a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default WithAppContext(FormulationDetails);
