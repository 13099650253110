import Button from "@mui/material/Button";
import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppLoader from "../../Components/AppLoader";
import Logout from "../logoutpopup";
import { ApiURL } from "../../Components/AppHelpers";
import { AppHeader } from "../../Components/AppHelpers";
const TakePhoto = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    let logIn = JSON.parse(localStorage.getItem("login"));
    if (logIn != undefined) {
      if (!logIn) {
        navigate("/log-in");
      }
    } else {
      navigate("/log-in");
    }
  }, []);

  const onFileChange = (event) => {
    hiddenFileInput.current.click();
    setIsLoading(true);
    const myfile = event.target.files[0];
    const formData = new FormData();
    formData.append("file", myfile);
    axios
      .post(`${ApiURL}upload-file`, formData, { headers: AppHeader })
      .then((res) => {
        if (res) {
          console.log("img_res", res.data.path);
          localStorage.setItem("uploaded_image", JSON.stringify(res.data.path));
          setTimeout(() => {
            navigate("/scan-image");
            setIsLoading(false);
          }, 1000);
        }
      })
      .catch((err) => {
        console.log("upload-file error ====>", err);
        setIsLoading(false);
      });
  };

  const onSelectPaintOEM = () => {
    navigate("/paint-selection");
  };

  return (
    <div className="">
      {isLoading && <AppLoader />}
      <img
        src={require("../../assets/bg.png")}
        className="h-[100vh] w-[100vw]"
      />
      <div className="absolute flex top-0 bottom-0 left-0 right-0 justify-center items-center flex-col">
        <div className="w-[80%] overflow-hidden justify-center items-center text-center">
          <p className="font-bold">
            WELCOME TO THE INTELLIGENT PAINT FORMULATION SOLUTION
          </p>
          {/* <input type="file" id="imagePicker" accept="image/*" /> */}
          {[
            { title: "Start", link: "/paint-selection" },
            // { title: "NEW PAINT FORMULATION", link: "/New-paint-formulation" },
            // { title: "APPROVALS", link: "/Approval-dashboard" },

            //   "KANNADA",
          ].map((i, index) => {
            return (
              <div
              key={index}
              style={{
                marginBottom: "2vh",
              }}
              className="mt-6"
              onClick={()=>{
                navigate(i?.link);
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  color: "#fff",
                  border: "1px solid #3D5A9F",
                  backgroundColor: "#3D5A9F",
                  paddingRight: 2,
                  paddingLeft:2,
                  '&:hover': {
                    backgroundColor: '#2D456D', // Change this to the desired light blue color
                  },
                }}
              >
                {i?.title}
              </Button>
            </div>
              
            );
          })}

          {/* <div className='mt-6'>
            <Button variant="outlined"
              endIcon={
                <div className='bg-[#2d3594] flex items-center absolute top-0 bottom-0 right-0 px-2'>
                  <CenterFocusWeakIcon />
                </div>
              }
              sx={{
                color: '#fff',
                border: '1px solid #3D5A9F',
                backgroundColor: '#3D5A9F',
                paddingRight: 6,
              }}
              onClick={() => {
                handleClick()
              }}
            >
              Take Photo
            </Button>
            <input
              type="file"
              accept="image/png,image/jpg,image/jpeg"
              ref={hiddenFileInput}
              onChange={onFileChange}
              style={{ display: 'none' }}
            />
          </div> */}
          <h1 className="text-blue-800 font-semibold mt-10"><Logout/></h1>
        </div>
      </div>
    </div>
  );
};

export default TakePhoto;
