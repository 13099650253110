import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { AppHeader } from '../Components/AppHelpers'

const ExitDialog = (props) => {
    const navigate = useNavigate();
    const [isSaveDetails, setIsSaveDetails] = useState(false);

    const handleCloseModel = () => {
        props.setIsShowCloseModelOnly(!props.isShowCloseModel)
    }

    const onSaveCloseModel = () => {
        setIsSaveDetails(true)
        setTimeout(() => {
            setIsSaveDetails(false)
            props.setIsShowCloseModel(!props.isShowCloseModel)
            // window.localStorage.removeItem("formulation_name");
            // window.localStorage.removeItem("formulation_image1");
            // window.localStorage.removeItem("formulationdetails");
            navigate('/paint-category')
        }, 3000);
    }
    const storeLocalData = () => {

        const paintManufacturer = JSON.parse(localStorage.getItem('paintManufacturer'))
        const paintCategory = JSON.parse(localStorage.getItem('paintCategory'))
        const color_name = JSON.parse(localStorage.getItem('color_name'))
        let formulation_name = JSON.parse(localStorage.getItem('formulation_name'))
        if (formulation_name != null) {
            formulation_name = formulation_name
        }
        else {
            formulation_name = "No name"
        }
        const formulation_image1 = JSON.parse(localStorage.getItem('formulation_image1'))
        const formulationdetails = JSON.parse(localStorage.getItem('formulationdetails'))
        var object = formulationdetails.reduce(
            (obj, item) => Object.assign(obj, { [item.label]: item.value }), {});

        axios({
            method: 'post',     //put
            url: 'http://localhost:5000/object/paint_formulation',
            headers: AppHeader,
            data: {
                formulation_name: formulation_name,
                paint_category_name: paintCategory,
                // colour_id:"Z2S",
                colour_name: color_name,
                tint_codes: object,
                manufacturer_name: paintManufacturer,
                image1: formulation_image1
            }
        });
    }

    return (
        <>
            <Dialog
                open={props.isShowCloseModel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: { borderRadius: 15 }
                }}
            >
                <DialogTitle id="alert-dialog-title" className="text-center font-bold uppercase rounded-lg">
                    {isSaveDetails ?
                        <div className='px-6 py-4'>
                            <div className='text-xs font-bold'>
                                Data Saved
                            </div>
                        </div>
                        :
                        <div className='px-6 py-4'>
                            <div className='text-xs font-bold'>
                                Are you sure you want to exit ?
                            </div>
                            <div className='mt-8'>
                                <Button variant="contained"
                                    endIcon={
                                        <div className='bg-[#1e3e88] flex items-center absolute top-0 bottom-0 right-0 px-2'>
                                            <SaveAltIcon className='' />
                                        </div>
                                    }
                                    sx={{
                                        backgroundColor: '#3c5a9f',
                                        paddingRight: 6,
                                    }}
                                    onClick={() => {
                                        // localStorage.setItem('formulationdetails', JSON.stringify(formulationDetails));
                                        // storeLocalData();
                                        onSaveCloseModel()
                                    }}
                                >
                                    Save & Exit
                                </Button>
                            </div>
                            <div className="flex justify-between w-full mt-8">
                                <div>
                                    <Button
                                        onClick={() => {
                                            handleCloseModel()
                                        }}
                                        variant="outlined"
                                        startIcon={<ChevronLeftIcon />}
                                        sx={{
                                            color: '#002783',
                                            border: '1px solid #002783',
                                        }}>
                                        Back
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        onClick={() => { navigate('/paint-category'); }}
                                        variant='outlined'
                                        endIcon={<ChevronRightIcon />}
                                        sx={{
                                            color: '#002783',
                                            border: '1px solid #002783',
                                        }}>
                                        Exit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                </DialogTitle>
            </Dialog>
        </>
    );
};

export default ExitDialog;
